import React, { Component } from "react"
import { Runtime, Inspector, Library } from "@observablehq/runtime"

import notebook from "./ae61b9bbfc9df7cb"
import "./ae61b9bbfc9df7cb/inspector.css"
import "./observablehq-tweaks.css"

class LoadRoiBloggingNotebook extends Component<
  { into: string },
  { mounted: boolean }
> {
  state = {
    mounted: false,
  }
  componentDidMount() {
    this.setState({ mounted: true })

    const root = document.getElementById(this.props.into)

    // Empty existing contents, which ought to be a static version for search engines
    root.innerHTML = ""

    const stdlib = new Library()

    const library = Object.assign({}, stdlib, { width })

    function width() {
      return stdlib.Generators.observe((notify) => {
        let width = notify(root.clientWidth)

        function resized() {
          let width1 = root.clientWidth
          if (width1 !== width) notify((width = width1))
        }

        window.addEventListener("resize", resized)
        return () => window.removeEventListener("resize", resized)
      })
    }

    // Use a modified version of the original Inspector.into function to be able to
    // trigger a window resize event when the notebook has loaded
    const into = function (container) {
      if (typeof container === "string") {
        container = document.querySelector(container)
        if (container == null) throw new Error("container not found")
      }
      return function (name) {
        const newDiv = container.appendChild(document.createElement("div"))
        // The embed cell is one of the last ones to render
        if (name === "embed") {
          newDiv.addEventListener("update", function () {
            // Triggers the reading progress lister in blog-theme to recalculate
            // the height of the content 1s after starting to render the embed cell
            setTimeout(() => {
              window.dispatchEvent(new Event("resize"))
            }, 1000)
            newDiv.removeEventListener("update", this)
          })
        }
        return new Inspector(newDiv)
      }
    }

    // Load the notebook, observing its cells with a default Inspector
    // that simply renders the value of each cell into the provided DOM node.
    // Source: https://observablehq.com/@observablehq/downloading-and-embedding-notebooks
    new Runtime(library).module(notebook, into(root))
  }

  render() {
    return <> </>
  }
}

export default LoadRoiBloggingNotebook
