// https://observablehq.com/@severo/inputs-setter@875
import define1 from "./e93997d5089d7165@2303.js";

export default function define(runtime, observer) {
  const main = runtime.module();
  const fileAttachments = new Map([["halloween-cat.png",new URL("./files/823440d2e8238e54b0ed26ff31e86f1ed7c09b44db08d0a47359f3ea1e7514b44e2a249e9c41632e4717156b8739347f7ff8b0fe8ff01d49bebbe0a00aceeef8",import.meta.url)]]);
  main.builtin("FileAttachment", runtime.fileAttachments(name => fileAttachments.get(name)));
  main.variable(observer()).define(["md","FileAttachment"], async function(md,FileAttachment){return(
md`# Inputs + setter


---

2021/02/04: **new [Observable Inputs](https://observablehq.com/@observablehq/inputs) are [settable](https://observablehq.com/@observablehq/synchronized-inputs) 🎉**. I you use them, you don't need this notebook!

This notebook is only useful for [legacy inputs](https://observablehq.com/@jashkenas/inputs).

As a consequence, this notebook is now _unmaintained_.

---

> Change the [@jashkenas/inputs](https://observablehq.com/@jashkenas/inputs) value programmatically.

I use the famous [Inputs](https://observablehq.com/@jashkenas/inputs) notebook by Jeremy Ashkenas all the time. But when I need to synchronize these inputs with a state, I have to repeat the same fragile tweaks in order to change their value programatically. In this notebook, I add a _new method called \`setValue\`_ that allows to change the value of an input programmatically.

${await FileAttachment("halloween-cat.png")
  .image()
  .then(i => (i.style.setProperty('width', '400px'), i))}

\`setValue\` takes the new value, updates the input internal state and display, and dispatches an 'input' event. Use it on the input (\`viewof a\`), not on the value itself (\`a\`):

~~~js
viewof a = slider()
viewof a.setValue(0.6)
~~~

The list of inputs: 
  * [\`slider\`](#sliderDemo)
  * [\`button\`](#buttonDemo)
  * [\`select\`](#selectDemo)
  * [\`color\`](#colorDemo)
  * [\`coordinates\`](#coordinatesDemo)
  * [\`worldMapCoordinates\`](#worldMapCoordinatesDemo)
  * [\`usaMapCoordinates\`](#usaMapCoordinatesDemo)
  * [\`date\`](#dateDemo)
  * [\`time\`](#timeDemo)
  * [\`file\`](#fileDemo)
  * [\`text\`](#textDemo)
  * [\`textarea\`](#textareaDemo)
  * [\`radio\`](#radioDemo)
  * [\`checkbox\`](#checkboxDemo)
  * [\`number\`](#numberDemo)
  * [\`password\`](#passwordDemo)

\`setValue\` has not been implemented for:
  * [\`autoSelect\`](#autoSelectDemo)
  * [\`file\`](#fileDemo)
  
Some details:

- an 'input' event is dispatched when the new value is set, so beware the loops if various inputs are listening to each other. Alternately, set the \`dispatch\` option to false:
  ~~~js
  viewof a.setValue(0.6, {dispatch: false})
  ~~~

  [TODO] The default should be the reverse: don't dispatch anything when setting a new value.

  > I don’t think it’s appropriate to dispatch an input event whenever the value is assigned — native inputs don’t behave that way, so neither do Observable Inputs.
  > _https://github.com/observablehq/inputs/issues/73#issuecomment-774724846_

- the new value is set inconditionnally, even if it's equal to the old value.
- I tried to modify as few code as possible, in order to make it easier to keep up-to-date with upstream.
`
)});
  main.variable(observer("sliderDemo")).define("sliderDemo", ["md"], function(md){return(
md`---
## Sliders

~~~js
import {slider} from "@severo/inputs-setter"
~~~

`
)});
  main.variable(observer("viewof a")).define("viewof a", ["slider"], function(slider){return(
slider()
)});
  main.variable(observer("a")).define("a", ["Generators", "viewof a"], (G, _) => G.input(_));
  main.variable(observer()).define(["a"], function(a){return(
a
)});
  main.variable(observer()).define(["html","viewof a"], function(html,$0){return(
Object.assign(html`<button type=button>Set a random value`, {
  onclick: event => $0.setValue(Math.random())
})
)});
  main.variable(observer("slider")).define("slider", ["addSetter","originalSlider"], function(addSetter,originalSlider){return(
function slider(...args) {
  return addSetter(originalSlider(...args));
}
)});
  main.variable(observer("buttonDemo")).define("buttonDemo", ["md"], function(md){return(
md`---
## Buttons

~~~js
import {button} from "@severo/inputs-setter"
~~~`
)});
  main.variable(observer("viewof b")).define("viewof b", ["button"], function(button){return(
button()
)});
  main.variable(observer("b")).define("b", ["Generators", "viewof b"], (G, _) => G.input(_));
  main.variable(observer()).define(["b"], function(b)
{
  b;
  return !this;
}
);
  main.variable(observer()).define(["randomElements","html","viewof b"], function(randomElements,html,$0)
{
  const elements = [...'abcdefghijklmnopqrstuvwxyz '];
  function randomText() {
    return randomElements(elements).join('');
  }
  return Object.assign(html`<button type=button>Set a random value`, {
    onclick: event => $0.setValue(randomText())
  });
}
);
  main.variable(observer()).define(["md"], function(md){return(
md`_Note that an \`input\` event is dispatched when the button's value is set, even if the value of the button seems to be "just the label"._`
)});
  main.variable(observer("button")).define("button", ["addSetter","originalButton"], function(addSetter,originalButton){return(
function button(...args) {
  return addSetter(originalButton(...args));
}
)});
  main.variable(observer("selectDemo")).define("selectDemo", ["md"], function(md){return(
md`---
## Dropdown Menus and Multiselects

~~~js
import {select} from "@severo/inputs-setter"
~~~`
)});
  main.variable(observer("viewof dd")).define("viewof dd", ["select"], function(select){return(
select(["Spring", "Summer", "Fall", "Winter"])
)});
  main.variable(observer("dd")).define("dd", ["Generators", "viewof dd"], (G, _) => G.input(_));
  main.variable(observer()).define(["dd"], function(dd){return(
dd
)});
  main.variable(observer("viewof dd2")).define("viewof dd2", ["select"], function(select){return(
select({
  description: "As a child, which vegetables did you refuse to eat?",
  options: [
    "Spinach",
    "Broccoli",
    "Brussels Sprouts",
    "Cauliflower",
    "Kale",
    "Turnips",
    "Green Beans",
    "Asparagus"
  ],
  multiple: true
})
)});
  main.variable(observer("dd2")).define("dd2", ["Generators", "viewof dd2"], (G, _) => G.input(_));
  main.variable(observer()).define(["dd2"], function(dd2){return(
dd2
)});
  main.variable(observer()).define(["html","viewof dd","randomElement","viewof dd2","randomElements"], function(html,$0,randomElement,$1,randomElements)
{
  return Object.assign(html`<button type=button>Set a random value`, {
    onclick: event => {
      $0.setValue(
        randomElement([...$0.input.options].map(o => o.value))
      );
      $1.setValue(
        randomElements([...$1.input.options].map(o => o.value))
      );
    }
  });
}
);
  main.variable(observer("select")).define("select", ["addSetter","originalSelect"], function(addSetter,originalSelect){return(
function select(...args) {
  const setter = (form, value) => {
    for (const option of form.input.options) {
      option.selected = Array.isArray(value)
        ? value.includes(option.value)
        : value === option.value;
    }
  };
  return addSetter(originalSelect(...args), { setter });
}
)});
  main.variable(observer("autoSelectDemo")).define("autoSelectDemo", ["md"], function(md){return(
md`---
## Autoselects
*A variant of an option menu, using an autocompleting text input, via HTML’s datalist element.* 

~~~js
import {autoSelect} from "@severo/inputs-setter"
~~~`
)});
  main.variable(observer("viewof as")).define("viewof as", ["autoSelect","usa"], function(autoSelect,usa){return(
autoSelect({
  options: usa.objects.states.geometries.map(d => d.properties.name),
  placeholder: "Search for a US state . . ."
})
)});
  main.variable(observer("as")).define("as", ["Generators", "viewof as"], (G, _) => G.input(_));
  main.variable(observer()).define(["as"], function(as){return(
as
)});
  main.variable(observer()).define(["md"], function(md){return(
md`_**No setter** is implemented for the autoselect input_`
)});
  main.variable(observer("colorDemo")).define("colorDemo", ["md"], function(md){return(
md`---
## Color Pickers

*value: a hexadecimal string, e.g. * \`"#bada55"\` 

~~~js
import {color} from "@severo/inputs-setter"
~~~`
)});
  main.variable(observer("viewof c")).define("viewof c", ["color"], function(color){return(
color()
)});
  main.variable(observer("c")).define("c", ["Generators", "viewof c"], (G, _) => G.input(_));
  main.variable(observer()).define(["c"], function(c){return(
c
)});
  main.variable(observer()).define(["md"], function(md){return(
md`_Note that the only [accepted values](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/color#Value) are "7-character string specifying an RGB color in hexadecimal format"_`
)});
  main.variable(observer()).define(["html","viewof c"], function(html,$0)
{
  function randomColor() {
    return `#${[0, 0, 0]
      .map(_ =>
        Math.floor(Math.random() * 256)
          .toString(16)
          .padStart(2, '0')
      )
      .join('')}`;
  }
  return Object.assign(html`<button type=button>Set a random value`, {
    onclick: event => {
      $0.setValue(randomColor());
    }
  });
}
);
  main.variable(observer("color")).define("color", ["addSetter","originalColor"], function(addSetter,originalColor){return(
function color(...args) {
  const setter = (form, value) => {
    // The following two lines are a bugfix for Safari, which hopefully can be removed in the future.
    form.input.value = '';
    form.input.value = value;
  };
  return addSetter(originalColor(...args), { setter });
}
)});
  main.variable(observer("coordinatesDemo")).define("coordinatesDemo", ["md"], function(md){return(
md` ---
## Coordinates

*value: an array pair of \`[longitude, latitude]\`, e.g. * \`[-122.27, 37.87]\` 

~~~js
import {coordinates} from "@severo/inputs-setter"
~~~`
)});
  main.variable(observer("viewof coords1")).define("viewof coords1", ["coordinates"], function(coordinates){return(
coordinates()
)});
  main.variable(observer("coords1")).define("coords1", ["Generators", "viewof coords1"], (G, _) => G.input(_));
  main.variable(observer()).define(["coords1"], function(coords1){return(
coords1
)});
  main.variable(observer()).define(["html","viewof coords1"], function(html,$0)
{
  function randomLongitude() {
    return Math.random() * 360 - 180;
  }
  function randomLatitude() {
    return Math.random() * 180 - 90;
  }
  return Object.assign(html`<button type=button>Set a random value`, {
    onclick: event => {
      $0.setValue([randomLongitude(), randomLatitude()]);
    }
  });
}
);
  main.variable(observer("coordinates")).define("coordinates", ["addSetter","originalCoordinates"], function(addSetter,originalCoordinates){return(
function coordinates(...args) {
  const setter = (form, value) => {
    let [lon, lat] = value;
    lon = lon != null ? lon : "";
    lat = lat != null ? lat : "";
    form.input[0].value = lon;
    form.input[1].value = lat;
  };
  return addSetter(originalCoordinates(...args), { setter });
}
)});
  main.variable(observer("worldMapCoordinatesDemo")).define("worldMapCoordinatesDemo", ["md"], function(md){return(
md` ---
## World Map Coordinates

*value: an array pair of \`[longitude, latitude]\`, e.g. * \`[-122.27, 37.87]\` 

~~~js
import {worldMapCoordinates} from "@severo/inputs-setter"
~~~`
)});
  main.variable(observer("viewof worldMap1")).define("viewof worldMap1", ["worldMapCoordinates"], function(worldMapCoordinates){return(
worldMapCoordinates([-122.27, 37.87])
)});
  main.variable(observer("worldMap1")).define("worldMap1", ["Generators", "viewof worldMap1"], (G, _) => G.input(_));
  main.variable(observer()).define(["worldMap1"], function(worldMap1){return(
worldMap1
)});
  main.variable(observer()).define(["html","viewof worldMap1"], function(html,$0)
{
  function randomLongitude() {
    return Math.random() * 360 - 180;
  }
  function randomLatitude() {
    return Math.random() * 180 - 90;
  }
  return Object.assign(html`<button type=button>Set a random value`, {
    onclick: event => {
      $0.setValue([randomLongitude(), randomLatitude()]);
    }
  });
}
);
  main.variable(observer("worldMapCoordinates")).define("worldMapCoordinates", ["addSetter","originalWorldMapCoordinates"], function(addSetter,originalWorldMapCoordinates){return(
function worldMapCoordinates(...args) {
  const setter = (form, value) => {
    form.setLonLat(...value);
  };
  return addSetter(originalWorldMapCoordinates(...args), { setter });
}
)});
  main.variable(observer()).define(["md"], function(md){return(
md`_I found no way to branch a setter on the original input without duplicating all the canvas drawing code, so that I had to edit the original code:_`
)});
  main.variable(observer("originalWorldMapCoordinates")).define("originalWorldMapCoordinates", ["html","DOM","d3geo","graticule","land","countries","input"], function(html,DOM,d3geo,graticule,land,countries,input){return(
function originalWorldMapCoordinates(config = {}) {
  const { value = [], title, description, width = 400 } = Array.isArray(config)
    ? { value: config }
    : config;
  const height = Math.round((210 / 400) * width);
  let [lon, lat] = value;
  lon = lon != null ? lon : null;
  lat = lat != null ? lat : null;
  const formEl = html`<form style="width: ${width}px;"></form>`;
  const context = DOM.context2d(width, height);
  const canvas = context.canvas;
  canvas.style.margin = "10px 0 3px";
  const projection = d3geo
    .geoNaturalEarth1()
    .precision(0.1)
    .fitSize([width, height], { type: "Sphere" });
  const path = d3geo.geoPath(projection, context).pointRadius(2.5);
  formEl.append(canvas);

  function draw() {
    context.fillStyle = "#fff";
    context.fillRect(0, 0, width, height);
    context.beginPath();
    path(graticule);
    context.lineWidth = 0.35;
    context.strokeStyle = `#ddd`;
    context.stroke();
    context.beginPath();
    path(land);
    context.fillStyle = `#f4f4f4`;
    context.fill();
    context.beginPath();
    path(countries);
    context.strokeStyle = `#aaa`;
    context.stroke();
    if (lon != null && lat != null) {
      const pointPath = { type: "MultiPoint", coordinates: [[lon, lat]] };
      context.beginPath();
      path(pointPath);
      context.fillStyle = `#f00`;
      context.fill();
    }
  }

  canvas.onclick = function(ev) {
    const { offsetX, offsetY } = ev;
    var coords = projection.invert([offsetX, offsetY]);
    // // start - difference with original code
    setLonLat(+coords[0].toFixed(2), +coords[1].toFixed(2));
    // // end - difference with original code
    canvas.dispatchEvent(new CustomEvent("input", { bubbles: true }));
  };

  draw();

  const form = input({
    type: "worldMapCoordinates",
    title,
    description,
    display: v =>
      html`<div style="width: ${width}px; white-space: nowrap; color: #444; text-align: center; font: 13px sans-serif; margin-bottom: 5px;">
            <span style="color: #777;">Longitude:</span> ${
              lon != null ? lon.toFixed(2) : ""
            }
            &nbsp; &nbsp; 
            <span style="color: #777;">Latitude:</span> ${
              lat != null ? lat.toFixed(2) : ""
            } 
          </div>`,
    getValue: () => [lon != null ? lon : null, lat != null ? lat : null],
    form: formEl
  });
  // // start - difference with original code
  function setLonLat(newLon, newLat) {
    lon = newLon;
    lat = newLat;
    draw();
  }
  form.setLonLat = setLonLat;
  // // end - difference with original code
  return form;
}
)});
  main.variable(observer("usaMapCoordinatesDemo")).define("usaMapCoordinatesDemo", ["md"], function(md){return(
md` ---
## U.S.A. Map Coordinates

*value: an array pair of \`[longitude, latitude]\`, e.g. * \`[-122.27, 37.87]\` 

~~~js
import {usaMapCoordinates} from "@severo/inputs-setter"
~~~`
)});
  main.variable(observer("viewof usaMap1")).define("viewof usaMap1", ["usaMapCoordinates"], function(usaMapCoordinates){return(
usaMapCoordinates([-122.27, 37.87])
)});
  main.variable(observer("usaMap1")).define("usaMap1", ["Generators", "viewof usaMap1"], (G, _) => G.input(_));
  main.variable(observer()).define(["usaMap1"], function(usaMap1){return(
usaMap1
)});
  main.variable(observer()).define(["html","viewof usaMap1"], function(html,$0)
{
  function randomLongitude() {
    return Math.random() * 50 - 125;
  }
  function randomLatitude() {
    return Math.random() * 20 + 25;
  }
  return Object.assign(html`<button type=button>Set a random value`, {
    onclick: event => {
      $0.setValue([randomLongitude(), randomLatitude()]);
    }
  });
}
);
  main.variable(observer("usaMapCoordinates")).define("usaMapCoordinates", ["addSetter","originalUsaMapCoordinates"], function(addSetter,originalUsaMapCoordinates){return(
function usaMapCoordinates(...args) {
  const setter = (form, value) => {
    form.setLonLat(...value);
  };
  return addSetter(originalUsaMapCoordinates(...args), { setter });
}
)});
  main.variable(observer()).define(["md"], function(md){return(
md`_I found no way to branch a setter on the original input without duplicating all the canvas drawing code, so that I had to edit the original code:_`
)});
  main.variable(observer("originalUsaMapCoordinates")).define("originalUsaMapCoordinates", ["html","DOM","d3geo","nation","states","input"], function(html,DOM,d3geo,nation,states,input){return(
function originalUsaMapCoordinates(config = {}) {
  const { value = [], title, description, width = 400 } = Array.isArray(config)
    ? { value: config }
    : config;
  const scale = width / 960;
  const height = scale * 600;
  let [lon, lat] = value;
  lon = lon != null ? lon : null;
  lat = lat != null ? lat : null;
  const formEl = html`<form style="width: ${width}px;"></form>`;
  const context = DOM.context2d(width, height);
  const canvas = context.canvas;
  canvas.style.margin = "5px 0 20px";
  const projection = d3geo
    .geoAlbersUsa()
    .scale(1280)
    .translate([480, 300]);
  const path = d3geo
    .geoPath()
    .context(context)
    .pointRadius(2.5 / scale);
  formEl.append(canvas);

  function draw() {
    context.clearRect(0, 0, width, height);
    context.save();
    context.scale(scale, scale);
    context.lineWidth = 0.35 / scale;
    context.beginPath();
    path(nation);
    context.fillStyle = `#f4f4f4`;
    context.fill();
    context.beginPath();
    path(states);
    context.strokeStyle = `#aaa`;
    context.stroke();
    if (lon != null && lat != null) {
      const pointPath = {
        type: "MultiPoint",
        coordinates: [projection([lon, lat])]
      };
      context.beginPath();
      path(pointPath);
      context.fillStyle = `#f00`;
      context.fill();
    }
    context.restore();
  }

  canvas.onclick = function(ev) {
    const { offsetX, offsetY } = ev;
    var coords = projection.invert([offsetX / scale, offsetY / scale]);
    // // start - difference with original code
    setLonLat(+coords[0].toFixed(2), +coords[1].toFixed(2));
    // // end - difference with original code
    canvas.dispatchEvent(new CustomEvent("input", { bubbles: true }));
  };

  draw();

  const form = input({
    type: "worldMapCoordinates",
    title,
    description,
    display: v =>
      html`<div style="position: absolute; width: ${width}px; white-space: nowrap; color: #444; text-align: center; font: 13px sans-serif; margin-top: -18px;">
            <span style="color: #777;">Longitude:</span> ${
              lon != null ? lon : ""
            }
            &nbsp; &nbsp; 
            <span style="color: #777;">Latitude:</span> ${
              lat != null ? lat : ""
            } 
          </div>`,
    getValue: () => [lon != null ? lon : null, lat != null ? lat : null],
    form: formEl
  });
  // // start - difference with original code
  function setLonLat(newLon, newLat) {
    lon = newLon;
    lat = newLat;
    draw();
  }
  form.setLonLat = setLonLat;
  // // end - difference with original code
  return form;
}
)});
  main.variable(observer("dateDemo")).define("dateDemo", ["md"], function(md){return(
md` ---
## Dates

*value: a YYYY-MM-DD formatted string: * \`"2016-11-08"\` 

~~~js
import {date} from "@severo/inputs-setter"
~~~`
)});
  main.variable(observer("viewof d")).define("viewof d", ["date"], function(date){return(
date()
)});
  main.variable(observer("d")).define("d", ["Generators", "viewof d"], (G, _) => G.input(_));
  main.variable(observer()).define(["d"], function(d){return(
d
)});
  main.variable(observer()).define(["html","viewof d"], function(html,$0)
{
  function randomDate() {
    return new Date(
      Math.round(Date.now() + (Math.random() - 0.5) * 1000000000000)
    )
      .toISOString()
      .substring(0, 10);
  }
  return Object.assign(html`<button type=button>Set a random value`, {
    onclick: event => {
      $0.setValue(randomDate());
    }
  });
}
);
  main.variable(observer("date")).define("date", ["addSetter","originalDate"], function(addSetter,originalDate){return(
function date(...args) {
  return addSetter(originalDate(...args));
}
)});
  main.variable(observer("timeDemo")).define("timeDemo", ["md"], function(md){return(
md` ---
## Times

*value: a HH:MM:SS formatted string: * \`"09:30:45"\`
<br>*(Time values are always in 24-hour format)*

~~~js
import {time} from "@severo/inputs-setter"
~~~`
)});
  main.variable(observer("viewof t")).define("viewof t", ["time"], function(time){return(
time()
)});
  main.variable(observer("t")).define("t", ["Generators", "viewof t"], (G, _) => G.input(_));
  main.variable(observer()).define(["t"], function(t){return(
t
)});
  main.variable(observer()).define(["html","viewof t"], function(html,$0)
{
  function randomTime() {
    return new Date(
      Math.round(Date.now() + (Math.random() - 0.5) * 1000000000000)
    )
      .toISOString()
      .substring(11, 16);
  }
  return Object.assign(html`<button type=button>Set a random value`, {
    onclick: event => {
      $0.setValue(randomTime());
    }
  });
}
);
  main.variable(observer("time")).define("time", ["addSetter","originalTime"], function(addSetter,originalTime){return(
function time(...args) {
  return addSetter(originalTime(...args));
}
)});
  main.variable(observer("fileDemo")).define("fileDemo", ["md"], function(md){return(
md`---
## File Upload
*Use the JavaScript [File API](https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications) to work with uploaded file contents.*

\`import {file} from "@severo/inputs-setter"\``
)});
  main.variable(observer("viewof e1")).define("viewof e1", ["file"], function(file){return(
file({
  title: "Photographs",
  description:
    "Only .jpg files are allowed in this example. Choose some images, and they’ll appear in the cell below.",
  accept: ".jpg",
  multiple: true
})
)});
  main.variable(observer("e1")).define("e1", ["Generators", "viewof e1"], (G, _) => G.input(_));
  main.variable(observer()).define(["html","e1","Files"], async function(html,e1,Files)
{
  const div = html`<div>`;
  for (var j = 0; j < e1.length; j++) {
    let file = e1[j];
    let img = html`<img height="125px" style="margin: 2px;" />`;
    img.src = await Files.url(e1[j]);
    div.append(img);
  }
  return div;
}
);
  main.variable(observer()).define(["e1"], function(e1){return(
e1
)});
  main.variable(observer()).define(["md"], function(md){return(
md`_**No setter** is implemented for the file upload_`
)});
  main.variable(observer("textDemo")).define("textDemo", ["md"], function(md){return(
md`---
## Text Inputs

~~~js
import {text} from "@severo/inputs-setter"
~~~`
)});
  main.variable(observer("viewof f")).define("viewof f", ["text"], function(text){return(
text()
)});
  main.variable(observer("f")).define("f", ["Generators", "viewof f"], (G, _) => G.input(_));
  main.variable(observer()).define(["f"], function(f){return(
f
)});
  main.variable(observer()).define(["randomElements","html","viewof f"], function(randomElements,html,$0)
{
  const elements = [...'abcdefghijklmnopqrstuvwxyz '];
  function randomText() {
    return randomElements(elements).join('');
  }
  return Object.assign(html`<button type=button>Set a random value`, {
    onclick: event => {
      $0.setValue(randomText());
    }
  });
}
);
  main.variable(observer("text")).define("text", ["addSetter","originalText"], function(addSetter,originalText){return(
function text(...args) {
  return addSetter(originalText(...args));
}
)});
  main.variable(observer("textareaDemo")).define("textareaDemo", ["md"], function(md){return(
md`---
## Textareas

~~~js
import {textarea} from "@severo/inputs-setter"
~~~`
)});
  main.variable(observer("viewof g")).define("viewof g", ["textarea"], function(textarea){return(
textarea()
)});
  main.variable(observer("g")).define("g", ["Generators", "viewof g"], (G, _) => G.input(_));
  main.variable(observer()).define(["g"], function(g){return(
g
)});
  main.variable(observer()).define(["randomElements","html","viewof g"], function(randomElements,html,$0)
{
  const elements = [...'abcdefghijklmnopqrstuvwxyz '];
  function randomText() {
    return randomElements(elements).join('');
  }
  return Object.assign(html`<button type=button>Set a random value`, {
    onclick: event => {
      $0.setValue(randomText());
    }
  });
}
);
  main.variable(observer("textarea")).define("textarea", ["addSetter","originalTextarea"], function(addSetter,originalTextarea){return(
function textarea(...args) {
  return addSetter(originalTextarea(...args));
}
)});
  main.variable(observer("radioDemo")).define("radioDemo", ["md"], function(md){return(
md`---
## Radio Buttons

~~~js
import {radio} from "@severo/inputs-setter"
~~~`
)});
  main.variable(observer("viewof r")).define("viewof r", ["radio"], function(radio){return(
radio([
  "Lust",
  "Gluttony",
  "Greed",
  "Sloth",
  "Wrath",
  "Envy",
  "Pride"
])
)});
  main.variable(observer("r")).define("r", ["Generators", "viewof r"], (G, _) => G.input(_));
  main.variable(observer()).define(["r"], function(r){return(
r
)});
  main.variable(observer()).define(["html","viewof r","randomElement"], function(html,$0,randomElement)
{
  return Object.assign(html`<button type=button>Set a random value`, {
    onclick: event => {
      $0.setValue(randomElement([...$0.input].map(o => o.value)));
    }
  });
}
);
  main.variable(observer("radio")).define("radio", ["addSetter","originalRadio"], function(addSetter,originalRadio){return(
function radio(...args) {
  const setter = (form, value) => {
    for (const radio of form.input) {
      radio.checked = radio.value === value;
    }
  };
  return addSetter(originalRadio(...args), { setter });
}
)});
  main.variable(observer("checkboxDemo")).define("checkboxDemo", ["md"], function(md){return(
md`---
## Checkboxes

~~~js
import {checkbox} from "@severo/inputs-setter"
~~~`
)});
  main.variable(observer("viewof ch")).define("viewof ch", ["checkbox"], function(checkbox){return(
checkbox([
  "Lust",
  "Gluttony",
  "Greed",
  "Sloth",
  "Wrath",
  "Envy",
  "Pride"
])
)});
  main.variable(observer("ch")).define("ch", ["Generators", "viewof ch"], (G, _) => G.input(_));
  main.variable(observer()).define(["ch"], function(ch){return(
ch
)});
  main.variable(observer()).define(["html","viewof ch","randomElements"], function(html,$0,randomElements)
{
  return Object.assign(html`<button type=button>Set a random value`, {
    onclick: event => {
      $0.setValue(randomElements([...$0].map(o => o.value)));
    }
  });
}
);
  main.variable(observer("checkbox")).define("checkbox", ["addSetter","originalCheckbox"], function(addSetter,originalCheckbox){return(
function checkbox(...args) {
  const setter = (form, value) => {
    for (const checkbox of form) {
      checkbox.checked = value.indexOf(checkbox.value) > -1;
    }
  };
  return addSetter(originalCheckbox(...args), { setter });
}
)});
  main.variable(observer("numberDemo")).define("numberDemo", ["md"], function(md){return(
md`---
## Numbers

~~~js
import {number} from "@severo/inputs-setter"
~~~`
)});
  main.variable(observer("viewof h")).define("viewof h", ["number"], function(number){return(
number()
)});
  main.variable(observer("h")).define("h", ["Generators", "viewof h"], (G, _) => G.input(_));
  main.variable(observer()).define(["h"], function(h){return(
h
)});
  main.variable(observer()).define(["html","viewof h"], function(html,$0)
{
  function randomInt() {
    return Math.round((Math.random() - 0.5) * 150);
  }
  return Object.assign(html`<button type=button>Set a random value`, {
    onclick: event => {
      $0.setValue(randomInt());
    }
  });
}
);
  main.variable(observer("number")).define("number", ["addSetter","originalNumber"], function(addSetter,originalNumber){return(
function number(...args) {
  return addSetter(originalNumber(...args));
}
)});
  main.variable(observer("passwordDemo")).define("passwordDemo", ["md"], function(md){return(
md`---
## Passwords

~~~js
import {password} from "@severo/inputs-setter"
~~~`
)});
  main.variable(observer("viewof i")).define("viewof i", ["password"], function(password){return(
password({ value: "password" })
)});
  main.variable(observer("i")).define("i", ["Generators", "viewof i"], (G, _) => G.input(_));
  main.variable(observer()).define(["i"], function(i){return(
i
)});
  main.variable(observer()).define(["randomElements","html","viewof i"], function(randomElements,html,$0)
{
  const elements = [...'abcdefghijklmnopqrstuvwxyz '];
  function randomText() {
    return randomElements(elements).join('');
  }
  return Object.assign(html`<button type=button>Set a random value`, {
    onclick: event => {
      $0.setValue(randomText());
    }
  });
}
);
  main.variable(observer("password")).define("password", ["addSetter","originalPassword"], function(addSetter,originalPassword){return(
function password(...args) {
  return addSetter(originalPassword(...args));
}
)});
  main.variable(observer()).define(["md"], function(md){return(
md`---

### Credits

_Developed for the [LIRIS M2i project](https://projet.liris.cnrs.fr/mi2/) by Sylvain Lesage._

_Image by [liftarn](https://openclipart.org/detail/324127/halloween-cat)_

---
`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`
### CHANGELOG

- 2020/11/22: creation

### TODO

- support autoSelect and file
- create a .value= setter, instead of a setValue() method. I'm not sure though: .setValue has the advantage to explicitly state that a setter exists and this can be tested.
- maybe: by default, don't dispatch an \`input\` event when an input's value is set using the setter, ie. set \`dispatch\` option to \`false\` by default, or remove completely the \`dispatch\` option. The reasons would be 1. to [mimic the \`<input>\` element](https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement), 2. a CustomEvent can be dispatched manually by the caller of the setter, while it's not possible to prevent the control to dispatch an event. The drawback would be that it would not be compatible with \`viewof\` anymore.
- maybe: the \`.value\` setter should return the value, or an empty string, as [the \`<input>\` element](https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement)
`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`### Code`
)});
  main.variable(observer("addSetter")).define("addSetter", function(){return(
function addSetter(form, config = {}) {
  if (!form._configForSettableInput) {
    // We cannot do anything - don't add the setValue method
    return form;
  }
  const { getValue, display, format } = form._configForSettableInput;

  const setter =
    config.setter ||
    function(form, newValue) {
      // Note that I first tried `form.input.setAttribute('value', newValue);`
      // but as stated in the doc (MDN/<input>),
      // > When specified in the HTML, this is the initial value
      // Thus, I don't touch the HTML 'value' attribute (it will keep the initial value)
      // and I modify the value *property*
      form.input.value = newValue;
    };

  function onNewValue() {
    // Exact copy from original 'input' code
    // This must be kept synchronized if original code is updated
    const value = getValue ? getValue(form.input) : form.input.value;
    if (form.output) {
      const out = display ? display(value) : format ? format(value) : value;
      if (out instanceof window.Element) {
        while (form.output.hasChildNodes()) {
          form.output.removeChild(form.output.lastChild);
        }
        form.output.append(out);
      } else {
        form.output.value = out;
      }
    }
    form.value = value;
  }

  form.setValue = function(newValue, { dispatch = true } = {}) {
    setter(form, newValue);
    onNewValue();
    // As setting the value property doesn't dispatch an event, I do it with a custom event
    if (dispatch) {
      form.dispatchEvent(new CustomEvent("input", { bubbles: true }));
    }
  };

  return form;
}
)});
  main.variable(observer("input2")).define("input2", ["html","d3format"], function(html,d3format){return(
function input2(config) {
  let {
    form,
    type = "text",
    attributes = {},
    action,
    getValue,
    title,
    description,
    format,
    display,
    submit,
    options
  } = config;
  const wrapper = html`<div></div>`;
  if (!form)
    form = html`<form>
	<input name=input type=${type} />
  </form>`;
  Object.keys(attributes).forEach(key => {
    const val = attributes[key];
    if (val != null) form.input.setAttribute(key, val);
  });
  if (submit)
    form.append(
      html`<input name=submit type=submit style="margin: 0 0.75em" value="${
        typeof submit == "string" ? submit : "Submit"
      }" />`
    );
  form.append(
    html`<output name=output style="font: 14px Menlo, Consolas, monospace; margin-left: 0.5em;"></output>`
  );
  if (title)
    form.prepend(
      html`<div style="font: 700 0.9rem sans-serif; margin-bottom: 3px;">${title}</div>`
    );
  if (description)
    form.append(
      html`<div style="font-size: 0.85rem; font-style: italic; margin-top: 3px;">${description}</div>`
    );
  if (format)
    format = typeof format === "function" ? format : d3format.format(format);
  if (action) {
    action(form);
  } else {
    const verb = submit
      ? "onsubmit"
      : type == "button"
      ? "onclick"
      : type == "checkbox" || type == "radio"
      ? "onchange"
      : "oninput";
    form[verb] = e => {
      e && e.preventDefault();
      const value = getValue ? getValue(form.input) : form.input.value;
      if (form.output) {
        const out = display ? display(value) : format ? format(value) : value;
        if (out instanceof window.Element) {
          while (form.output.hasChildNodes()) {
            form.output.removeChild(form.output.lastChild);
          }
          form.output.append(out);
        } else {
          form.output.value = out;
        }
      }
      form.value = value;
      if (verb !== "oninput")
        form.dispatchEvent(new CustomEvent("input", { bubbles: true }));
    };
    if (verb !== "oninput")
      wrapper.oninput = e => e && e.stopPropagation() && e.preventDefault();
    if (verb !== "onsubmit") form.onsubmit = e => e && e.preventDefault();
    form[verb]();
  }
  while (form.childNodes.length) {
    wrapper.appendChild(form.childNodes[0]);
  }
  form.append(wrapper);
  form._configForSettableInput = { getValue, display, format }; // <- Only difference with original code
  return form;
}
)});
  main.variable(observer("randomElement")).define("randomElement", function(){return(
function randomElement(elements) {
  return elements[Math.floor(Math.random() * elements.length)];
}
)});
  main.variable(observer("randomElements")).define("randomElements", function(){return(
function randomElements(elements) {
  let number = Math.round(Math.random() * elements.length);
  const remaining = [...elements];
  const picked = [];
  while (number >= 0) {
    number -= 1;
    const nextIdx = Math.floor(Math.random() * remaining.length);
    const next = remaining.splice(nextIdx, 1)[0];
    picked.push(next);
  }
  return picked;
}
)});
  main.variable(observer()).define(["md"], function(md){return(
md`### Dependencies`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`Preliminary import`
)});
  const child1 = runtime.module(define1);
  main.import("d3format", child1);
  main.import("d3geo", child1);
  main.import("graticule", child1);
  main.import("land", child1);
  main.import("countries", child1);
  main.import("states", child1);
  main.import("nation", child1);
  main.import("usa", child1);
  main.variable(observer()).define(["md"], function(md){return(
md`Final import, using \`input2\` as the new \`input\` cell`
)});
  const child2 = runtime.module(define1).derive([{name: "input2", alias: "input"}], main);
  main.import("slider", "originalSlider", child2);
  main.import("button", "originalButton", child2);
  main.import("select", "originalSelect", child2);
  main.import("autoSelect", child2);
  main.import("color", "originalColor", child2);
  main.import("coordinates", "originalCoordinates", child2);
  main.import("date", "originalDate", child2);
  main.import("time", "originalTime", child2);
  main.import("file", child2);
  main.import("text", "originalText", child2);
  main.import("textarea", "originalTextarea", child2);
  main.import("radio", "originalRadio", child2);
  main.import("checkbox", "originalCheckbox", child2);
  main.import("number", "originalNumber", child2);
  main.import("password", "originalPassword", child2);
  main.import("input", child2);
  return main;
}
