// https://observablehq.com/@severo/log-slider@211
import define1 from "./6178a4f4c55b2040@875.js";

export default function define(runtime, observer) {
  const main = runtime.module();
  const fileAttachments = new Map([["log slider bug.png",new URL("./files/f40ad81cae5c6cd7b5ab1a9284be765849ea9db0d1e85084f6e21b97b1682684ed350b874a6b55916ba88b7e445af508d4d8d4d127ab1e5b360011f79f1580b5",import.meta.url)]]);
  main.builtin("FileAttachment", runtime.fileAttachments(name => fileAttachments.get(name)));
  main.variable(observer()).define(["md","tex"], function(md,tex){return(
md`# Log slider

A log-scale version of the [\`slider\`](https://observablehq.com/@jashkenas/inputs#sliderDemo) input by @jashkenas<sup>1</sup>.

~~~js
import {logSlider} from '@severo/log-slider'
~~~

It uses a logarithmic scale instead of a linear scale between min and max, and thus is adapted for ratios, ie between 0.001 and 1000.

*Warning*: the values must be strictly positive. An exception is thrown if \`min\` or \`max\` options are negative or equal to zero.

That's the reason why some default values differ from @jashkenas' \`select\`:

- \`min\`: \`0.01\` instead of \`0\`
- \`value\`: ${tex`\exp\left(\frac{\ln\left(min\right) + \ln\left(max\right)}{2}\right)`} instead of ${tex`\frac{min + max}{2}`}
- step: ignored, forced to "any". Use \`precision\` instead.
`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`## Examples`
)});
  main.variable(observer("viewof a")).define("viewof a", ["logSlider"], function(logSlider){return(
logSlider()
)});
  main.variable(observer("a")).define("a", ["Generators", "viewof a"], (G, _) => G.input(_));
  main.variable(observer("viewof a1")).define("viewof a1", ["logSlider"], function(logSlider){return(
logSlider({
  min: 0.01,
  max: 1,
  step: 0.01,
  format: ".0%",
  description: "0.01 to one, formatted as a percentage"
})
)});
  main.variable(observer("a1")).define("a1", ["Generators", "viewof a1"], (G, _) => G.input(_));
  main.variable(observer("viewof a1_1")).define("viewof a1_1", ["logSlider"], function(logSlider){return(
logSlider({
  min: 0.01,
  max: 1,
  step: 0.01,
  format: v => `${Math.round(100 * v)} per cent`,
  description: "0.01 to one, formatted with a custom function"
})
)});
  main.variable(observer("a1_1")).define("a1_1", ["Generators", "viewof a1_1"], (G, _) => G.input(_));
  main.variable(observer("viewof a2")).define("viewof a2", ["logSlider"], function(logSlider){return(
logSlider({
  min: 1,
  max: 1e9,
  precision: -3,
  value: 3250000,
  format: ",",
  description:
    "One to one billion, in steps of one thousand, formatted as a (US) number"
})
)});
  main.variable(observer("a2")).define("a2", ["Generators", "viewof a2"], (G, _) => G.input(_));
  main.variable(observer("viewof a3")).define("viewof a3", ["logSlider"], function(logSlider){return(
logSlider({
  min: 1,
  max: 100,
  precision: 0,
  value: 10,
  title: "Integers",
  description: "Integers from one through 100"
})
)});
  main.variable(observer("a3")).define("a3", ["Generators", "viewof a3"], (G, _) => G.input(_));
  main.variable(observer("viewof a4")).define("viewof a4", ["logSlider"], function(logSlider){return(
logSlider({
  min: 0.9,
  max: 1.1,
  precision: 3,
  description: "A high precision slider example"
})
)});
  main.variable(observer("a4")).define("a4", ["Generators", "viewof a4"], (G, _) => G.input(_));
  main.variable(observer("viewof a5")).define("viewof a5", ["slider"], function(slider){return(
slider({
  min: 0.9,
  max: 1.1,
  precision: 3,
  submit: true,
  description: "The same as a4, but only changes value on submit"
})
)});
  main.variable(observer("a5")).define("a5", ["Generators", "viewof a5"], (G, _) => G.input(_));
  main.variable(observer("viewof a6")).define("viewof a6", ["logSlider"], function(logSlider){return(
logSlider({ description: "Use .setValue() to update the value" })
)});
  main.variable(observer("a6")).define("a6", ["Generators", "viewof a6"], (G, _) => G.input(_));
  main.variable(observer()).define(["html","viewof a6","a6"], function(html,$0,a6)
{
  const form = html`<form onsubmit="return false;">
<button name=half>half a6</button>
<button name=double>double a6</button>
</form>`;
  form.half.onclick = () => {
    $0.setValue(a6 / 2);
  };
  form.double.onclick = () => {
    $0.setValue(a6 * 2);
  };
  return form;
}
);
  main.variable(observer()).define(["md"], function(md){return(
md`---

<sup>1</sup> I use "by @jashkenas" because as a non-native English speaker, I don't understand the subtleties between @jashkenas' (_The Associated Press Stylebook_) and @jashkenas's (_The Chicago Manual of Style_), and couldn't decide.
`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`---

### Credits

_Developed for the [LIRIS M2i project](https://projet.liris.cnrs.fr/mi2/) by Sylvain Lesage._

_Contribution by [@chonghorizons](https://observablehq.com/@chonghorizons) (fix the setter)_

---
`
)});
  main.variable(observer()).define(["md","FileAttachment"], async function(md,FileAttachment){return(
md`### CHANGELOG

- 2021/01/14: fix the .setValue() method. Thanks to [@chonghorizons](https://observablehq.com/@chonghorizons)
- 2020/11/20: creation

### TODO

- use \`step\` option to set the precision, instead of ignoring it (ie. step=10 will set precision=-1)
- when precision is set, use toFixed in format option in order to avoid values such as 3.0000000002
- fix a precision bug?

  <img src="${await FileAttachment(
    "log slider bug.png"
  ).url()}" style="width: 50%" />
  <!-- width: 50% is because the screenshot has been taken with zoom=200% -->
`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`### Code`
)});
  main.variable(observer("logSlider")).define("logSlider", ["slider"], function(slider){return(
function logSlider(options = {}) {
  if (options.min !== undefined && options.min <= 0) {
    throw new RangeError(`min option must be strictly positive`);
  }
  if (options.max !== undefined && options.max <= 0) {
    throw new RangeError(`min option must be strictly positive`);
  }
  const min = options.min !== undefined ? options.min : 0.01;
  const max = options.max !== undefined ? options.max : 1;
  const getValue =
    options.getValue !== undefined ? options.getValue : input => input.value;
  const precision = options.precision !== undefined ? options.precision : 2;

  const clamp = x => Math.max(min, Math.min(max, x));
  const logMin = Math.log10(min);
  const logMax = Math.log10(max);
  const logGetValue = input =>
    clamp(
      Math.round(Math.pow(10, precision + +getValue(input))) *
        Math.pow(10, -precision)
    );
  const logValue =
    options.value !== undefined
      ? Math.log10(options.value)
      : (logMin + logMax) / 2;
  const logStep = "any";

  const control = slider({
    ...options,
    min: logMin,
    max: logMax,
    value: logValue,
    step: logStep,
    getValue: logGetValue
  });

  // Hack to transform the value before setting it. It would be better to provide
  // an option for it in https://observablehq.com/@severo/inputs-setter
  // Thanks to https://observablehq.com/@chonghorizons for pointing the bug
  const setValue = control.setValue;
  control.setValue = function(value) {
    if (value <= 0) {
      throw new RangeError(`the value must be strictly positive`);
    }
    setValue.call(this, Math.log10(value));
  };
  return control;
}
)});
  main.variable(observer()).define(["md"], function(md){return(
md`### Dependencies`
)});
  const child1 = runtime.module(define1);
  main.import("slider", child1);
  return main;
}
