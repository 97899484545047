import define1 from "./e93997d5089d7165@2303.js";
import define2 from "./f407132152a9d20b@211.js";
import define3 from "./564da070b01aa308@1096.js";
import define4 from "./a2e58f97fd5e8d7c@567.js";

export default function define(runtime, observer) {
  const main = runtime.module();
  main.variable(observer()).define(["md"], function(md){return(
md`# When is "creating your own blog" worth it financially?

This is for those that are thinking about creating your own blog or already have a blog but not making any money from it. Use these cost-benefit analysis tools to explore when it is worthwhile to invest time and effort into blogging vs getting paid a fixed amount by the hour for something that does not involve blogging.

It is purposefully limited to financial returns, omitting softer values that may very well make blogging worth it despite zero financial gain / severe financial losses / high short-term opportunity costs, including:

* Improving your personal brand value (eg increasing the likelihood of search engine results on your name turns up something that looks professional)
* Experiencing the joy of writing down your thoughts for the world to see
* Improving as a writer/thinker
* etc

**Caveat and disclaimer**: This model is not a true-to-life depiction of your future financial blogging outcomes, but rather a simple educational model that is capable of sketching out possible financial blogging outcomes under specific conditions. It comes with no guarantees of any kind and the creators of the model can not be held responsible for any use or misuse of the model. With that said, let's move on :)
`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`## Overall structure

As you traverse through the following sections, keep in mind this general assumed structure of the endeavor outlined below:

* **Phase 1** - Experience-gaining phase
* **Phase 2** - Active content creation phase
* **Phase 3** - "Passive" blog/newsletter maintenance phase

In reality, there will be no clear-cut distinction between the phases, and you may find yourself giving up on the "passive income" ideal and instead keep working actively on the blog longer than planned.`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`## Part 1: Estimating upfront knowledge/experience-investments`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`First, indicate how far you are along the path towards blogging at all today, eg. basic blogging proficiency. (If you are already up and running with a blog, no matter the size or its professionalism, leave the slider all the way to the left)`
)});
  main.variable(observer("viewof initial_hours_before_operational$")).define("viewof initial_hours_before_operational$", ["logSlider","querystring"], function(logSlider,querystring){return(
logSlider({
  min: 0.499, 
  max: 100, 
  precision: 0,
  step: 1,
  value: querystring("initial_hours_before_operational",0)+0.499,
  format: v => Math.round(v) > 0 ? `${Math.round(v)} hours` : `I am already up and running with a blog`,
  description: "How many hours of unpaid effort will it take to reach the relevant experience, skill levels and circumstances to be able to blog at all?"
})
)});
  main.variable(observer("initial_hours_before_operational$")).define("initial_hours_before_operational$", ["Generators", "viewof initial_hours_before_operational$"], (G, _) => G.input(_));
  main.variable(observer()).define(["md"], function(md){return(
md`Now, indicate how much work you need to do to be able to consider yourself an "experienced" blogger. Feel free to choose any definition of "experienced" that you prefer. The only assumption we make is that you won't be able to reap any substantial financial benefits from the content you produced while "becoming experienced". We basically assume that you start a new blog from scratch after becoming "experienced", or at least revamp you existing blog in a significant way. (If you are already an "experienced" blogger, leave the slider all the way to the left)`
)});
  main.variable(observer("viewof initial_hours_before_experienced$")).define("viewof initial_hours_before_experienced$", ["logSlider"], function(logSlider){return(
logSlider({
  min: 0.499, 
  max: 10000, 
  precision: 0,
  step: 1,
  value: 0.499,
  format: v => Math.round(v) > 0 ? `${Math.round(v)} hours` : `I am already an "experienced" blogger`,
  description: "How many hours of unpaid effort work do you need to invest to be able to consider yourself an \"experienced\" blogger?"
})
)});
  main.variable(observer("initial_hours_before_experienced$")).define("initial_hours_before_experienced$", ["Generators", "viewof initial_hours_before_experienced$"], (G, _) => G.input(_));
  main.variable(observer()).define(["md"], function(md){return(
md`Finally, indicate how much time you will dedicate to gaining the relevant experience:`
)});
  main.variable(observer("viewof hours_per_month_to_gain_experience$")).define("viewof hours_per_month_to_gain_experience$", ["logSlider"], function(logSlider){return(
logSlider({
  min: 0.499, 
  max: 200,
  precision: 0,
  value: 0.499,
  step: 1,
  format: v => `I'll spend on average ${Math.round(v)} hours per month gaining experience before getting started "for real"`,
  description: "Hours invested each month to gain experience"
})
)});
  main.variable(observer("hours_per_month_to_gain_experience$")).define("hours_per_month_to_gain_experience$", ["Generators", "viewof hours_per_month_to_gain_experience$"], (G, _) => G.input(_));
  main.variable(observer()).define(["md"], function(md){return(
md`### Part 1 Summary`
)});
  main.variable(observer()).define(["phase1_summary"], function(phase1_summary){return(
phase1_summary()
)});
  main.variable(observer()).define(["md"], function(md){return(
md`## Part 2: Estimating upfront investments into creating and marketing quality content`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`One of the attractive parts of creating your own blog is the prospect of producing content under a specific period of time and then passively reap the benefits of that work over a longer period of time. 

In this part, estimate the investments into producing content and partaking in activities intented to grow a large and lasting audience to a blog that you own.`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`Once you are an experienced blogger, how much time do you plan/hope/estimate that each piece of published content takes you to produce, publish and market/forward it to relevant audiences?`
)});
  main.variable(observer("viewof upfront_time_investment_per_piece_of_published_and_marketed_content")).define("viewof upfront_time_investment_per_piece_of_published_and_marketed_content", ["logSlider"], function(logSlider){return(
logSlider({
  min: 1, 
  max: 100,
  precision: 1,
  value: 1,
  step: 1,
  format: v => `${Math.round(v*100)/100} hour${v===1 ? "" : "s"} per piece of content`,
  description: "Hours invested upfront in each piece of content (on average)"
})
)});
  main.variable(observer("upfront_time_investment_per_piece_of_published_and_marketed_content")).define("upfront_time_investment_per_piece_of_published_and_marketed_content", ["Generators", "viewof upfront_time_investment_per_piece_of_published_and_marketed_content"], (G, _) => G.input(_));
  main.variable(observer("viewof pieces_of_content")).define("viewof pieces_of_content", ["logSlider"], function(logSlider){return(
logSlider({
  min: 1, 
  max: 1000,
  value: 1,
  step: 1,
  format: v => `${Math.round(v)} pieces of content`,
  description: "How many pieces of content do you plan to create?"
})
)});
  main.variable(observer("pieces_of_content")).define("pieces_of_content", ["Generators", "viewof pieces_of_content"], (G, _) => G.input(_));
  main.variable(observer()).define(["md"], function(md){return(
md`### Time spent on your blog per month`
)});
  main.variable(observer("viewof hours_per_month_spent_on_content_creation$")).define("viewof hours_per_month_spent_on_content_creation$", ["logSlider"], function(logSlider){return(
logSlider({
  min: 0.499, 
  max: 200,
  precision: 0,
  value: 0.499,
  step: 1,
  format: v => `${Math.round(v)} hours per month on average spent on content creation`,
  description: "Hours invested each month on content creation"
})
)});
  main.variable(observer("hours_per_month_spent_on_content_creation$")).define("hours_per_month_spent_on_content_creation$", ["Generators", "viewof hours_per_month_spent_on_content_creation$"], (G, _) => G.input(_));
  main.variable(observer()).define(["md"], function(md){return(
md`### Time spent on anything else than content creation (eg hosting, maintaining and tweaking your existing content, sending newsletters etc)`
)});
  main.variable(observer("viewof hours_per_month_necessary_to_keep_things_running$")).define("viewof hours_per_month_necessary_to_keep_things_running$", ["logSlider"], function(logSlider){return(
logSlider({
  min: 0.499, 
  max: 100,
  value: 0.499,
  precision: 0,
  step: 1,
  format: v => `${Math.round(v)} hours per month spent hosting, maintaining and tweaking your existing content`,
  description: "Hours invested each month to hosting, maintaining and tweaking your existing content, eg keeping the blog software online, replying to comments, making edits, etc."
})
)});
  main.variable(observer("hours_per_month_necessary_to_keep_things_running$")).define("hours_per_month_necessary_to_keep_things_running$", ["Generators", "viewof hours_per_month_necessary_to_keep_things_running$"], (G, _) => G.input(_));
  main.variable(observer()).define(["md"], function(md){return(
md`### Part 2 Summary`
)});
  main.variable(observer()).define(["phase2_summary"], function(phase2_summary){return(
phase2_summary()
)});
  main.variable(observer()).define(["md"], function(md){return(
md`### Hours month-by-month spent on blog`
)});
  main.variable(observer()).define(["graph"], function(graph){return(
graph([
  {
    mark: {
      type: "line",
      stroke: "darkred",
      "interpolate": "step-after"
    },
    encoding: {
      y: {
        field: "hours",
        type: "quantitative",
        "axis": {
          title: "Hours",
          format: "(0.0f",
        }
      }
    }
  }
])
)});
  main.variable(observer()).define(["md"], function(md){return(
md`## Part 3: Visitors per month over time`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`### Lifespan and traffic distribution over time for your content`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`
The following values will depend heavily on the type of content you plan to create, eg if you are creating short term sensational content optimized for spreading through social media, or long term evergreen content that may be able to attract visitors for a long time via search engines and backlinks.

You might also plan to keep a certain amount of created content for subscribers only / behind a paywall.`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`### Amount of published pieces of content over time`
)});
  main.variable(observer("viewof fraction_of_content_published")).define("viewof fraction_of_content_published", ["slider"], function(slider){return(
slider({
  min: 0, 
  max: 1, 
  step: 0.1, 
  value: 0,
  format: ".0%",
  description: "Percentage of content that will be published (as opposed to subscriber-only / paid content)"
})
)});
  main.variable(observer("fraction_of_content_published")).define("fraction_of_content_published", ["Generators", "viewof fraction_of_content_published"], (G, _) => G.input(_));
  main.variable(observer()).define(["graph"], function(graph){return(
graph([
  {
    mark: {
      type: "line",
      stroke: "black",
      "interpolate": "step-after"
    },
    encoding: {
      y: {
        field: "aggregate_published_pieces_of_content",
        type: "quantitative",
        "axis": {
          title: "Published pieces of content",
          format: "(0.0f",
        }
      }
    }
  }
])
)});
  main.variable(observer()).define(["md"], function(md){return(
md`### Amount of live/relevant published pieces of content over time`
)});
  main.variable(observer("viewof lifespan_in_months_per_piece_of_content")).define("viewof lifespan_in_months_per_piece_of_content", ["slider"], function(slider){return(
slider({
  min: 1, 
  max: 12*10,
  value: 1,
  step: 1,
  format: v => `${v} months (average content lifespan)`,
  description: "Average amount of months that the piece of content will be relevant to its audience"
})
)});
  main.variable(observer("lifespan_in_months_per_piece_of_content")).define("lifespan_in_months_per_piece_of_content", ["Generators", "viewof lifespan_in_months_per_piece_of_content"], (G, _) => G.input(_));
  main.variable(observer()).define(["graph"], function(graph){return(
graph([
  {
    mark: {
      type: "line",
      stroke: "black",
      "interpolate": "step-after"
    },
    encoding: {
      y: {
        field: "currently_live_published_pieces_of_content",
        type: "quantitative",
        "axis": {
          title: "Live published pieces of content",
          format: "(0.0f",
        }
      }
    }
  }
])
)});
  main.variable(observer()).define(["md"], function(md){return(
md`### Traffic over time`
)});
  main.variable(observer("viewof visitors_monthly_per_piece_of_content")).define("viewof visitors_monthly_per_piece_of_content", ["logSlider"], function(logSlider){return(
logSlider({
  min: 1, 
  max: 1000000,
  precision: 0,
  value: 1,
  step: 1,
  format: v => `${v} average of visitors each month per piece of published content during its lifespan`,
  description: "" // Average amounto of visitors each month per piece of published content during its lifespan
})
)});
  main.variable(observer("visitors_monthly_per_piece_of_content")).define("visitors_monthly_per_piece_of_content", ["Generators", "viewof visitors_monthly_per_piece_of_content"], (G, _) => G.input(_));
  main.variable(observer()).define(["graph"], function(graph){return(
graph([
  {
    mark: {
      type: "line",
      stroke: "black",
      "interpolate": "step-after"
    },
    encoding: {
      y: {
        field: "visitors",
        type: "quantitative",
        "axis": {
          title: "Visitors",
          format: "(0.0f",
        }

      }
    }
  },
])
)});
  main.variable(observer()).define(["md"], function(md){return(
md`### Total/accumulated amount of visitors that has ever visited your blog`
)});
  main.variable(observer()).define(["graph"], function(graph){return(
graph([
  {
    mark: {
      type: "line",
      stroke: "black",
      "interpolate": "step-after"
    },
    encoding: {
      y: {
        field: "aggregate_visitors",
        type: "quantitative",
        "axis": {
          title: "Visitors",
          format: "(0.0f",
        }

      }
    }
  },
])
)});
  main.variable(observer()).define(["md"], function(md){return(
md`## Part 4: Sizing potential returns on investment`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`Now, play around with the sliders below each potential business model to reflect your assumptions and specific circumstances`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`### Monetization strategy 1 - Ad/affiliate revenue
Place ads and affiliate links at select places and reap the benefits`
)});
  main.variable(observer("viewof fraction_of_published_content_that_contains_ad_or_affiliate_links")).define("viewof fraction_of_published_content_that_contains_ad_or_affiliate_links", ["slider"], function(slider){return(
slider({
  min: 0, 
  max: 1, 
  step: 0.1, 
  value: 0,
  format: ".0%",
  description: "Percentage of published content that will include ad/affiliate links"
})
)});
  main.variable(observer("fraction_of_published_content_that_contains_ad_or_affiliate_links")).define("fraction_of_published_content_that_contains_ad_or_affiliate_links", ["Generators", "viewof fraction_of_published_content_that_contains_ad_or_affiliate_links"], (G, _) => G.input(_));
  main.variable(observer("viewof ad_affiliate_income_per_1k_visitors$")).define("viewof ad_affiliate_income_per_1k_visitors$", ["logSlider","currency"], function(logSlider,currency){return(
logSlider({
  min: 0.049, 
  max: 100, 
  value: 0.499,
  precision: 2,
  format: v => `${v}${currency}`,
  description: "Average net income per 1000 monthly visitors exposed to content with ad/affiliate revenue during the lifetime of the blog"
})
)});
  main.variable(observer("ad_affiliate_income_per_1k_visitors$")).define("ad_affiliate_income_per_1k_visitors$", ["Generators", "viewof ad_affiliate_income_per_1k_visitors$"], (G, _) => G.input(_));
  main.variable(observer()).define(["graph"], function(graph){return(
graph([
  {
    mark: {
      type: "line",
      stroke: "black",
      "interpolate": "step-after"
    },
    encoding: {
      y: {
        field: "ad_affiliate_revenue",
        type: "quantitative",
        "axis": {
          title: "Ad/affiliate revenue",
          format: "($.0f",
        }
      }
    }
  },
])
)});
  main.variable(observer()).define(["md"], function(md){return(
md`### Monetization strategy 2 - Build up a niche mailing list and offer exclusive content to your subscribers`
)});
  main.variable(observer("viewof fraction_of_published_content_that_contains_subscription_prompt")).define("viewof fraction_of_published_content_that_contains_subscription_prompt", ["slider"], function(slider){return(
slider({
  min: 0, 
  max: 1, 
  step: 0.1, 
  value: 0,
  format: ".0%",
  description: "Percentage of published content that will include an opportunity to sign up for your mailing list"
})
)});
  main.variable(observer("fraction_of_published_content_that_contains_subscription_prompt")).define("fraction_of_published_content_that_contains_subscription_prompt", ["Generators", "viewof fraction_of_published_content_that_contains_subscription_prompt"], (G, _) => G.input(_));
  main.variable(observer("viewof visitors_subscribing_to_mailing_list_per_1k_visitors$")).define("viewof visitors_subscribing_to_mailing_list_per_1k_visitors$", ["logSlider"], function(logSlider){return(
logSlider({
  min: 0.01,
  max: 1000, 
  precision: 1,
  value: 0.01,
  format: v => `${Math.round(v*10)/10} visitors subscribe to your mailing list per 1000 visitors`,
  description: "Average amount of visitors that subscribe to your mailing list per 1000 visitors"
})
)});
  main.variable(observer("visitors_subscribing_to_mailing_list_per_1k_visitors$")).define("visitors_subscribing_to_mailing_list_per_1k_visitors$", ["Generators", "viewof visitors_subscribing_to_mailing_list_per_1k_visitors$"], (G, _) => G.input(_));
  main.variable(observer("viewof lifespan_in_months_per_mailing_list_subscriber")).define("viewof lifespan_in_months_per_mailing_list_subscriber", ["slider"], function(slider){return(
slider({
  min: 1, 
  max: 12*10,
  value: 1,
  step: 1,
  format: v => `${v} months (average mailing list subscriber lifespan)`,
  description: "Average amount of months that the mailing list subscriber will stay subscribed"
})
)});
  main.variable(observer("lifespan_in_months_per_mailing_list_subscriber")).define("lifespan_in_months_per_mailing_list_subscriber", ["Generators", "viewof lifespan_in_months_per_mailing_list_subscriber"], (G, _) => G.input(_));
  main.variable(observer()).define(["md"], function(md){return(
md`#### Amount of mailing list subscribers over time`
)});
  main.variable(observer()).define(["graph"], function(graph){return(
graph([
  {
    mark: {
      type: "line",
      stroke: "black",
      "interpolate": "step-after"
    },
    encoding: {
      y: {
        field: "current_amount_of_mailing_list_subscribers",
        type: "quantitative",
        "axis": {
          title: "Mailing list subscribers",
          format: "(0.0f",
        }

      }
    }
  },
])
)});
  main.variable(observer()).define(["md"], function(md){return(
md`#### Amount of mailing list revenue month by month`
)});
  main.variable(observer("viewof income_per_subscriber_and_month")).define("viewof income_per_subscriber_and_month", ["logSlider","currency"], function(logSlider,currency){return(
logSlider({
  min: 0.01, 
  max: 1000, 
  value: 0.01,
  precision: 2,
  format: v => `${Math.round(v*100)/100}${currency} average profit per subscriber and month`,
  description: "Average profit per mailing list subscriber per month during the lifetime of the blog"
})
)});
  main.variable(observer("income_per_subscriber_and_month")).define("income_per_subscriber_and_month", ["Generators", "viewof income_per_subscriber_and_month"], (G, _) => G.input(_));
  main.variable(observer()).define(["graph"], function(graph){return(
graph([
  {
    mark: {
      type: "line",
      stroke: "black",
      "interpolate": "step-after"
    },
    encoding: {
      y: {
        field: "mailing_list_revenue",
        type: "quantitative",
        "axis": {
          title: "Mailing list revenue",
          format: "($.00f",
        }
      }
    }
  },
])
)});
  main.variable(observer()).define(["md"], function(md){return(
md`## Part 5: What is the opportunity cost for spending time on blogging?`
)});
  main.variable(observer()).define(["md","phase1_hours_per_month","phase2_hours_per_month","phase3_hours_per_month"], function(md,phase1_hours_per_month,phase2_hours_per_month,phase3_hours_per_month){return(
md`Above, you have stated that you will spend **${phase1_hours_per_month} hours per month** during the "gaining experience"-phase, **${phase2_hours_per_month} hours per month** during active content creation phase and **${phase3_hours_per_month} hours per month** during a more "passive" maintenance phase. From where do you get/take this time?

**Note**: "As part of paid work" means any time for which you get adequately paid by the hour to blog in some way or another. This could be as a freelancer, as part of a salaried occupation, employed as a blogger etc.`
)});
  main.variable(observer("viewof hours_spent_divided")).define("viewof hours_spent_divided", ["ternarySlider","width"], function(ternarySlider,width){return(
ternarySlider({
  labels: ['As part of paid work', 'Instead of paid work', "Less sleep\n/ free time"], 
  width: width > 600 ? 600 : width,
  value: [0,1,1],
})
)});
  main.variable(observer("hours_spent_divided")).define("hours_spent_divided", ["Generators", "viewof hours_spent_divided"], (G, _) => G.input(_));
  main.variable(observer()).define(["md","phase1_hours_per_month","phase1_end_month","phase_summary","phase2_hours_per_month","phase2_end_month","phase3_hours_per_month"], function(md,phase1_hours_per_month,phase1_end_month,phase_summary,phase2_hours_per_month,phase2_end_month,phase3_hours_per_month){return(
md`### Phase 1 time sources (${phase1_hours_per_month} hours per month for ${phase1_end_month} months)
${phase_summary(phase1_hours_per_month)}

### Phase 2 time sources (${phase2_hours_per_month} hours per month for ${phase2_end_month-phase1_end_month} months)
${phase_summary(phase2_hours_per_month)}

### Phase 3 time sources (${phase3_hours_per_month} hours per month ongoing)
${phase_summary(phase3_hours_per_month)}
`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`### Opportunity costs per month (not including loss of sleep / free time)`
)});
  main.variable(observer("viewof opportunity_cost_per_hour")).define("viewof opportunity_cost_per_hour", ["logSlider","currency"], function(logSlider,currency){return(
logSlider({
  min: 1, 
  max: 1000,
  value: 1,
  precision: 0,
  step: 1,
  format: v => `${v}${currency} forfeited income per hour for time spent on own blog instead of paid work`,
  description: "Income per hour doing that you no longer can earn due to partial time spent on blogging."
})
)});
  main.variable(observer("opportunity_cost_per_hour")).define("opportunity_cost_per_hour", ["Generators", "viewof opportunity_cost_per_hour"], (G, _) => G.input(_));
  main.variable(observer()).define(["graph"], function(graph){return(
graph([
  {
    mark: {
      type: "line",
      stroke: "red",
      "interpolate": "step-after"
    },
    encoding: {
      y: {
        field: "opportunity_costs",
        type: "quantitative",
        "axis": {
          title: "Opportunity costs",
          format: "($.0f",
        }
      }
    }
  },
  /*
      {
        mark: {
          type: "errorband",
        },
        encoding: {
          y: {
            field: "revenue",
            type: "quantitative",
          },
          y2: {
            field: "profit",
          },
        }
      },
      */
])
)});
  main.variable(observer()).define(["md"], function(md){return(
md`# End results: Comparing the potential return on investment to other options

`
)});
  main.variable(observer()).define(["md","phase1_summary","phase2_summary","phase3_hours_per_month","numbers_at_end_of_phase3","months","formatCurrency","hours_spent_divided","opportunity_cost_per_hour"], function(md,phase1_summary,phase2_summary,phase3_hours_per_month,numbers_at_end_of_phase3,months,formatCurrency,hours_spent_divided,opportunity_cost_per_hour){return(
md`**Recap of your blogging endeavour:** 

${phase1_summary()}

${phase2_summary()}

After the active content creation phase, you will spend **${phase3_hours_per_month} hours** per month in keeping the blog / newsletter running.

After **${numbers_at_end_of_phase3.month} months** (${Math.round(months/12*10)/10} years), ${numbers_at_end_of_phase3.aggregate_profit > 0 ? "your blog will stop being profitable and ": ""}you will have accumulated revenue of **${formatCurrency(Math.round(numbers_at_end_of_phase3.aggregate_revenue))}** after spending **${Math.round(numbers_at_end_of_phase3.aggregate_hours)} hours** in total, resulting in an average income of **${formatCurrency(Math.round(numbers_at_end_of_phase3.aggregate_profit/numbers_at_end_of_phase3.aggregate_hours))} per hour**.

**${Math.round((numbers_at_end_of_phase3.aggregate_revenue > 0 ? numbers_at_end_of_phase3.aggregate_ad_affiliate_revenue/numbers_at_end_of_phase3.aggregate_revenue : 0)*100)}%** of the revenue will have come from ad/affiliate revenue and **${Math.round((numbers_at_end_of_phase3.aggregate_revenue > 0 ? numbers_at_end_of_phase3.aggregate_mailing_list_revenue/numbers_at_end_of_phase3.aggregate_revenue : 0)*100)}%** from mailing list monetization. 

Gaining experience, creating and running the blog took away ${Math.round(numbers_at_end_of_phase3.aggregate_hours*hours_spent_divided[1])} hours from paid work which would have been compensated with **${formatCurrency(opportunity_cost_per_hour)} per hour**. This represents forfeited revenue of **${formatCurrency(Math.round(numbers_at_end_of_phase3.aggregate_hours*hours_spent_divided[1]*opportunity_cost_per_hour))}** in total (on top of the **${Math.round(numbers_at_end_of_phase3.aggregate_hours*hours_spent_divided[2])} hours of less sleep/free time**). In the end, creating the blog thus resulted in **${formatCurrency(Math.round(Math.abs(numbers_at_end_of_phase3.aggregate_profit)))} ${numbers_at_end_of_phase3.aggregate_profit > 0 ? "more" : "less"} revenue** compared to not creating the blog.`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`### Revenue (green), Opportunity costs (red) and Profit/Loss (blue) month by month`
)});
  main.variable(observer()).define(["graph"], function(graph){return(
graph([
  {
    mark: {
      type: "line",
      stroke: "blue",
      strokeWidth: 5,
    },
    encoding: {
      y: {
        field: "profit",
        type: "quantitative",
        "axis": {
          title: "Revenue, Costs, Profit/Loss",
          format: "($.0f",
        }
      }
    }
  },
  {
    mark: {
      type: "line",
      stroke: "green",
      // fill: "green",
    },
    encoding: {
      y: {
        field: "revenue",
        type: "quantitative",
        format: "($.0f",
      }
    }
  },
  {
    mark: {
      type: "line",
      stroke: "red",
      // fill: "red",
    },
    encoding: {
      y: {
        field: "costs",
        type: "quantitative",
        format: "($.0f",
      }
    }
  },
  {
    mark: {
      type: "line",
      stroke: "grey",
      strokeDash: [8,8],
    },
    encoding: {
      y: {
        field: "zero",
        type: "quantitative",
        format: "($.0f",
      }
    }
  },
  /*
      {
        mark: {
          type: "errorband",
        },
        encoding: {
          y: {
            field: "revenue",
            type: "quantitative",
          },
          y2: {
            field: "profit",
          },
        }
      },
      */
])
)});
  main.variable(observer()).define(["md"], function(md){return(
md`### Aggregated: Total Revenue (green), Opportunity costs (red) and Profit/Loss (blue) over time`
)});
  main.variable(observer()).define(["graph"], function(graph){return(
graph([
  {
    mark: {
      type: "line",
      stroke: "blue",
      strokeWidth: 5,
      // fill: "blue",
    },
    encoding: {
      y: {
        field: "aggregate_profit",
        type: "quantitative",
        "axis": {
          title: "Total Revenue, Costs, Profit/Loss",
          format: "($.0f",
        }

      }
    }
  },
  {
    mark: {
      type: "line",
      stroke: "green",
      // fill: "green",
    },
    encoding: {
      y: {
        field: "aggregate_revenue",
        type: "quantitative",
        format: "($.0f",
      }
    }
  },
  {
    mark: {
      type: "line",
      stroke: "red",
      // fill: "red",
    },
    encoding: {
      y: {
        field: "aggregate_costs",
        type: "quantitative",
        format: "($.0f",
      }
    }
  },
  {
    mark: {
      type: "line",
      stroke: "grey",
      strokeDash: [8,8],
    },
    encoding: {
      y: {
        field: "zero",
        type: "quantitative",
        format: "($.0f",
      }
    }
  },
  /*
      {
        mark: {
          type: "errorband",
        },
        encoding: {
          y: {
            field: "revenue",
            type: "quantitative",
          },
          y2: {
            field: "profit",
          },
        }
      },
      */
])
)});
  main.variable(observer()).define(["md"], function(md){return(
md`## Final words

Now when you see the recap and possible future results, how does it feel? Is this a reasonable result? How well have you looked into the assumptions of revenue, conversion rates, churn etc? Remember that this is a model that allows for forecasting of ridiculous scenarios and it pays to talk to industry experts and understand what metrics they achieve, and adjust the inputs to this model to what you believe that you will be able to achieve. Also remember the dimension of risk/luck which will affect real world results dramatically.`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`# Calculations`
)});
  main.variable(observer("initial_hours_before_operational")).define("initial_hours_before_operational", ["initial_hours_before_operational$"], function(initial_hours_before_operational$){return(
Math.round(initial_hours_before_operational$)
)});
  main.variable(observer("initial_hours_before_experienced")).define("initial_hours_before_experienced", ["initial_hours_before_experienced$"], function(initial_hours_before_experienced$){return(
Math.round(initial_hours_before_experienced$)
)});
  main.variable(observer("initial_hours_before_operational_and_experienced")).define("initial_hours_before_operational_and_experienced", ["initial_hours_before_operational","initial_hours_before_experienced"], function(initial_hours_before_operational,initial_hours_before_experienced){return(
initial_hours_before_operational + initial_hours_before_experienced
)});
  main.variable(observer("hours_per_month_to_gain_experience")).define("hours_per_month_to_gain_experience", ["hours_per_month_to_gain_experience$"], function(hours_per_month_to_gain_experience$){return(
Math.round(hours_per_month_to_gain_experience$)
)});
  main.variable(observer("months_before_experienced")).define("months_before_experienced", ["initial_hours_before_operational_and_experienced","hours_per_month_to_gain_experience"], function(initial_hours_before_operational_and_experienced,hours_per_month_to_gain_experience){return(
initial_hours_before_operational_and_experienced > 0 ? initial_hours_before_operational_and_experienced/hours_per_month_to_gain_experience : 0
)});
  main.variable(observer("phase1_hours")).define("phase1_hours", ["initial_hours_before_operational_and_experienced"], function(initial_hours_before_operational_and_experienced){return(
initial_hours_before_operational_and_experienced
)});
  main.variable(observer("phase1_summary")).define("phase1_summary", ["phase1_hours","md","months_before_experienced"], function(phase1_hours,md,months_before_experienced){return(
() => phase1_hours > 0 ? md`It will take you **${phase1_hours} hours** spent over a period of **${Math.ceil(months_before_experienced)} month${Math.ceil(months_before_experienced) > 1 ? "s" : ""}** until you are an \"experienced blogger\" and thus be able to efficiently create quality content for your blog.` : md`You are already an \"experienced blogger\" and thus able to efficiently create and monetize quality content for your blog.`
)});
  main.variable(observer("hours_per_month_necessary_to_keep_things_running")).define("hours_per_month_necessary_to_keep_things_running", ["hours_per_month_necessary_to_keep_things_running$"], function(hours_per_month_necessary_to_keep_things_running$){return(
Math.round(hours_per_month_necessary_to_keep_things_running$)
)});
  main.variable(observer("hours_per_month_spent_on_content_creation")).define("hours_per_month_spent_on_content_creation", ["hours_per_month_spent_on_content_creation$"], function(hours_per_month_spent_on_content_creation$){return(
Math.round(hours_per_month_spent_on_content_creation$)
)});
  main.variable(observer("total_hours_per_month_during_active_content_creation_phase")).define("total_hours_per_month_during_active_content_creation_phase", ["hours_per_month_spent_on_content_creation","hours_per_month_necessary_to_keep_things_running"], function(hours_per_month_spent_on_content_creation,hours_per_month_necessary_to_keep_things_running){return(
hours_per_month_spent_on_content_creation+hours_per_month_necessary_to_keep_things_running
)});
  main.variable(observer("pieces_of_content_per_month")).define("pieces_of_content_per_month", ["total_hours_per_month_during_active_content_creation_phase","hours_per_month_necessary_to_keep_things_running","upfront_time_investment_per_piece_of_published_and_marketed_content"], function(total_hours_per_month_during_active_content_creation_phase,hours_per_month_necessary_to_keep_things_running,upfront_time_investment_per_piece_of_published_and_marketed_content){return(
(total_hours_per_month_during_active_content_creation_phase-hours_per_month_necessary_to_keep_things_running) / upfront_time_investment_per_piece_of_published_and_marketed_content
)});
  main.variable(observer("active_months")).define("active_months", ["pieces_of_content","pieces_of_content_per_month"], function(pieces_of_content,pieces_of_content_per_month){return(
pieces_of_content / pieces_of_content_per_month
)});
  main.variable(observer("phase2_hours")).define("phase2_hours", ["upfront_time_investment_per_piece_of_published_and_marketed_content","pieces_of_content"], function(upfront_time_investment_per_piece_of_published_and_marketed_content,pieces_of_content){return(
upfront_time_investment_per_piece_of_published_and_marketed_content*pieces_of_content
)});
  main.variable(observer("phase2_summary")).define("phase2_summary", ["md","phase2_hours_per_month","phase2_end_month","phase1_end_month","phase2_hours","pieces_of_content_per_month"], function(md,phase2_hours_per_month,phase2_end_month,phase1_end_month,phase2_hours,pieces_of_content_per_month){return(
() => md`You will spend **${phase2_hours_per_month} hours** per month over a period of **${phase2_end_month-phase1_end_month} months** (or **${Math.round(phase2_hours)} hours** in total over a span of ${Math.round((phase2_end_month-phase1_end_month)/12*10)/10} years) on quality content creation, creating **${Math.round(pieces_of_content_per_month*10)/10} pieces of content per month** on average`
)});
  main.variable(observer("phase1_hours_per_month")).define("phase1_hours_per_month", ["months_before_experienced","hours_per_month_to_gain_experience"], function(months_before_experienced,hours_per_month_to_gain_experience){return(
months_before_experienced > 1 ? hours_per_month_to_gain_experience : months_before_experienced*hours_per_month_to_gain_experience
)});
  main.variable(observer("phase2_hours_per_month")).define("phase2_hours_per_month", ["total_hours_per_month_during_active_content_creation_phase"], function(total_hours_per_month_during_active_content_creation_phase){return(
total_hours_per_month_during_active_content_creation_phase
)});
  main.variable(observer("phase3_hours_per_month")).define("phase3_hours_per_month", ["hours_per_month_necessary_to_keep_things_running"], function(hours_per_month_necessary_to_keep_things_running){return(
hours_per_month_necessary_to_keep_things_running
)});
  main.variable(observer("phase_summary")).define("phase_summary", ["md","hours_spent_divided"], function(md,hours_spent_divided){return(
(phase_hours_per_month) => md`
* **${Math.round(phase_hours_per_month*hours_spent_divided[0])} hours** per month on average spent on your blog as part of paid work
* **${Math.round(phase_hours_per_month*hours_spent_divided[1])} hours** per month on average spent on your blog that would/could otherwise be spent on work (trading time for money)
* **${Math.round(phase_hours_per_month*hours_spent_divided[2])} hours** per month on average spent on blog that would otherwise be spent on sleep / free time
`
)});
  main.variable(observer("phase1_end_month")).define("phase1_end_month", ["months_before_experienced"], function(months_before_experienced){return(
Math.ceil(months_before_experienced)
)});
  main.variable(observer("phase2_end_month")).define("phase2_end_month", ["phase1_end_month","active_months"], function(phase1_end_month,active_months){return(
phase1_end_month+Math.ceil(active_months)
)});
  main.variable(observer("ad_affiliate_income_per_1k_visitors")).define("ad_affiliate_income_per_1k_visitors", ["ad_affiliate_income_per_1k_visitors$"], function(ad_affiliate_income_per_1k_visitors$){return(
Math.round(ad_affiliate_income_per_1k_visitors$*10)/10
)});
  main.variable(observer("visitors_subscribing_to_mailing_list_per_1k_visitors")).define("visitors_subscribing_to_mailing_list_per_1k_visitors", ["visitors_subscribing_to_mailing_list_per_1k_visitors$"], function(visitors_subscribing_to_mailing_list_per_1k_visitors$){return(
Math.round(visitors_subscribing_to_mailing_list_per_1k_visitors$*10)/10
)});
  main.variable(observer("numbers_month_by_month")).define("numbers_month_by_month", ["months","phase1_end_month","phase1_hours_per_month","active_months","phase2_hours_per_month","pieces_of_content_per_month","fraction_of_content_published","phase3_hours_per_month","lifespan_in_months_per_piece_of_content","visitors_monthly_per_piece_of_content","fraction_of_published_content_that_contains_ad_or_affiliate_links","fraction_of_published_content_that_contains_subscription_prompt","ad_affiliate_income_per_1k_visitors","visitors_subscribing_to_mailing_list_per_1k_visitors","lifespan_in_months_per_mailing_list_subscriber","income_per_subscriber_and_month","hours_spent_divided","opportunity_cost_per_hour","phase2_end_month"], function(months,phase1_end_month,phase1_hours_per_month,active_months,phase2_hours_per_month,pieces_of_content_per_month,fraction_of_content_published,phase3_hours_per_month,lifespan_in_months_per_piece_of_content,visitors_monthly_per_piece_of_content,fraction_of_published_content_that_contains_ad_or_affiliate_links,fraction_of_published_content_that_contains_subscription_prompt,ad_affiliate_income_per_1k_visitors,visitors_subscribing_to_mailing_list_per_1k_visitors,lifespan_in_months_per_mailing_list_subscriber,income_per_subscriber_and_month,hours_spent_divided,opportunity_cost_per_hour,phase2_end_month)
{

  const debug = false;
  const $numbers_month_by_month = []
  let currently_live_published_pieces_of_content = 0;
  let current_amount_of_mailing_list_subscribers = 0;
  let aggregate_created_pieces_of_content = 0;
  let aggregate_published_pieces_of_content = 0;
  let aggregate_visitors = 0;
  let aggregate_revenue = 0;
  let aggregate_ad_affiliate_revenue = 0;
  let aggregate_mailing_list_revenue = 0;
  let aggregate_costs = 0;
  let aggregate_profit = 0;
  let aggregate_hours = 0;
  for (let m = 0; m <= months; m++) {
    debug && console.log({m});
    let hours = 0;
    let created_pieces_of_content = 0;
    let published_pieces_of_content = 0;
    let change_in_live_published_pieces_of_content = 0;
    let visitors = 0;
    // phase 1
    const phase1_start_month = 0;
    if (m >= phase1_start_month && m < phase1_end_month) {
      hours = phase1_hours_per_month;
    }
    // phase 2
    const phase2_start_month = phase1_end_month;
    const phase2_end_month = phase1_end_month+active_months;
    if (m >= phase2_start_month && m < phase2_end_month) {
      hours = phase2_hours_per_month;
      created_pieces_of_content = pieces_of_content_per_month;
      published_pieces_of_content = pieces_of_content_per_month*fraction_of_content_published;
      change_in_live_published_pieces_of_content = published_pieces_of_content;
    }
    // phase 3
    const phase3_start_month = phase2_end_month;
    if (m >= phase3_start_month && m < months) {
      hours = phase3_hours_per_month;
    }
    // change_in_live_published_pieces_of_content
    const content_lifespan_back_then = $numbers_month_by_month[m-lifespan_in_months_per_piece_of_content];
    debug && console.log({content_lifespan_back_then})
    if (content_lifespan_back_then !== undefined) {
      change_in_live_published_pieces_of_content += -content_lifespan_back_then.published_pieces_of_content;
    }
    currently_live_published_pieces_of_content += change_in_live_published_pieces_of_content;
    visitors = Math.round(currently_live_published_pieces_of_content*visitors_monthly_per_piece_of_content)
    
    const visitors_seeing_ads = Math.round(visitors*fraction_of_published_content_that_contains_ad_or_affiliate_links)
    const visitors_seeing_subscription_prompt = Math.round(visitors*fraction_of_published_content_that_contains_subscription_prompt)
    
    const ad_affiliate_revenue = Math.round(visitors_seeing_ads/1000*ad_affiliate_income_per_1k_visitors*100)/100
    const new_subscribers = visitors_seeing_subscription_prompt/1000*visitors_subscribing_to_mailing_list_per_1k_visitors
    current_amount_of_mailing_list_subscribers += new_subscribers

    // churned_subscribers
    let churned_subscribers = 0;
    const subscriber_lifespan_back_then = $numbers_month_by_month[m-lifespan_in_months_per_mailing_list_subscriber];
    debug && console.log({subscriber_lifespan_back_then})
    if (subscriber_lifespan_back_then !== undefined) {
      churned_subscribers = -subscriber_lifespan_back_then.new_subscribers;
    }
    current_amount_of_mailing_list_subscribers += churned_subscribers
    
    const mailing_list_revenue = Math.round(current_amount_of_mailing_list_subscribers*income_per_subscriber_and_month*100)/100

    const revenue = ad_affiliate_revenue + mailing_list_revenue;

    const monetary_costs = -0; // TODO
    const opportunity_costs = -hours*hours_spent_divided[1]*opportunity_cost_per_hour;
    const costs = monetary_costs + opportunity_costs
    const profit = revenue + costs;
    
    aggregate_created_pieces_of_content += created_pieces_of_content;
    aggregate_published_pieces_of_content += published_pieces_of_content;
    aggregate_visitors += visitors;
    aggregate_revenue += revenue;
    aggregate_ad_affiliate_revenue += ad_affiliate_revenue;
    aggregate_mailing_list_revenue += mailing_list_revenue;
    aggregate_costs += costs;
    aggregate_profit += profit;
    aggregate_hours += hours;
    const numbers_this_month = {
      month: m,
      hours,
      aggregate_created_pieces_of_content,
      aggregate_published_pieces_of_content,
      aggregate_visitors,
      aggregate_revenue,
      aggregate_costs,
      aggregate_profit,
      aggregate_hours,
      created_pieces_of_content,
      published_pieces_of_content,
      change_in_live_published_pieces_of_content,
      currently_live_published_pieces_of_content,
      visitors,
      new_subscribers,
      churned_subscribers,
      current_amount_of_mailing_list_subscribers,
      revenue,
      ad_affiliate_revenue,
      mailing_list_revenue,
      costs,
      profit,
      zero: 0,
      // for further breakdowns:
      aggregate_ad_affiliate_revenue,
      aggregate_mailing_list_revenue,
      monetary_costs,
      opportunity_costs,
      phase1_end_month_ceil: Math.ceil(phase1_end_month),
      phase2_end_month_ceil: Math.ceil(phase2_end_month),
    }
    debug && console.log({numbers_this_month})
    $numbers_month_by_month[m] = numbers_this_month
  }
  
  if (!$numbers_month_by_month) {
    return [];
  }
      
  const first_unprofitable_month_after_phase3_numbers = $numbers_month_by_month.find(numbers => numbers.month > phase2_end_month && numbers.profit < 0)
  
  const $phase3_end_month = first_unprofitable_month_after_phase3_numbers ? first_unprofitable_month_after_phase3_numbers.month : months;
  
  return $numbers_month_by_month.map(numbers => ({...numbers, phase3_end_month_ceil: Math.ceil($phase3_end_month)}))
}
);
  main.variable(observer("numbers_at_end_of_phase3")).define("numbers_at_end_of_phase3", ["numbers_month_by_month"], function(numbers_month_by_month)
{
  const phase3_end_month = numbers_month_by_month[0].phase3_end_month_ceil;
  return numbers_month_by_month[phase3_end_month];
}
);
  main.variable(observer("urlSearchParams")).define("urlSearchParams", ["URLSearchParams","html"], function(URLSearchParams,html){return(
new URLSearchParams(html`<a href>`.search)
)});
  main.variable(observer("querystring")).define("querystring", ["urlSearchParams"], function(urlSearchParams){return(
(attribute, defaultValue) => {
  const qsValue = urlSearchParams.get(attribute);
  if (qsValue === null) {
    return defaultValue;
  }
  return qsValue;
}
)});
  main.variable(observer()).define(["querystring"], function(querystring){return(
querystring("foo", "bar")
)});
  main.variable(observer("state")).define("state", ["initial_hours_before_operational"], function(initial_hours_before_operational){return(
{
  initial_hours_before_operational
}
)});
  main.variable(observer("graph")).define("graph", ["embed","locale","phase2_end_month","numbers_at_end_of_phase3","vegalite","numbers_month_by_month","width"], function(embed,locale,phase2_end_month,numbers_at_end_of_phase3,vegalite,numbers_month_by_month,width){return(
(customLayers) => {
  embed.vega.timeFormatLocale(locale); // show dates in German
  embed.vega.formatLocale(locale); // show numbers with German groupings/ separators

  const layer = [
    ...customLayers,
    {
      "mark": {
        type: "rule",
        "strokeDash": [2,2],
      },
      "encoding": {
        "x": {"field": "phase1_end_month_ceil"},
        "color": {"value": "purple"},
        "size": {"value": 1}
      }
    },
    {
      "mark": {
        type: "rule",
        "strokeDash": [2,2],
      },
      "encoding": {
        "x": {"field": "phase2_end_month_ceil"},
        "color": {"value": "purple"},
        "size": {"value": 1},
      }
    },
    {
      "mark": {
        "type": "text",
        "align": "left",
        "color": "lightgrey",
        "baseline": "middle",
        "dx": 3,
        "dy": 10
      },
      "encoding": {
        "x": {"field": "phase1_end_month_ceil", "type": "quantitative"},
        "y": {"value": 0, "type": "quantitative"},
        "text": {"value": "Phase 2"}
      }
    },
    {
      "mark": {
        "type": "text",
        "align": "left",
        "color": "lightgrey",
        "baseline": "middle",
        "dx": 3,
        "dy": 10
      },
      "encoding": {
        "x": {"field": "phase2_end_month_ceil", "type": "quantitative"},
        "y": {"value": 0, "type": "quantitative"},
        "text": {"value": "Phase 3"}
      }
    }];
  
if (phase2_end_month < Infinity && numbers_at_end_of_phase3.aggregate_profit > 0) {
layer.push(
    {
      "mark": {
        type: "rule",
        "strokeDash": [2,2],
      },
      "encoding": {
        "x": {"field": "phase3_end_month_ceil"},
        "color": {"value": "purple"},
        "size": {"value": 1},
      }
    },
  {
      "mark": {
        "type": "text",
        "align": "left",
        "color": "lightgrey",
        "baseline": "middle",
        "dx": 3,
        "dy": 10
      },
      "encoding": {
        "x": {"field": "phase3_end_month_ceil", "type": "quantitative"},
        "y": {"value": 0, "type": "quantitative"},
        "text": {"value": "No longer profitable"}
      }
    }  
  );
}

return vegalite({
  data: { values: numbers_month_by_month },
  background: "transparent",
  view: {"fill": "#fff"},
  autosize: "fit",
  width: width,
  encoding: {
    x: {
      field: "month",
      title: "Month",
      type: "quantitative"
    },
  },
  layer,
})
}
)});
  main.variable(observer("d3FormatLocale")).define("d3FormatLocale", ["d3","locale"], function(d3,locale){return(
d3.formatLocale(locale)
)});
  main.variable(observer("formatCurrency")).define("formatCurrency", ["d3FormatLocale"], function(d3FormatLocale){return(
d3FormatLocale.format("$,")
)});
  main.variable(observer()).define(["md"], function(md){return(
md`# Settings`
)});
  main.variable(observer("currency")).define("currency", function(){return(
"€"
)});
  main.variable(observer("months")).define("months", ["months_before_experienced","active_months","lifespan_in_months_per_piece_of_content","lifespan_in_months_per_mailing_list_subscriber"], function(months_before_experienced,active_months,lifespan_in_months_per_piece_of_content,lifespan_in_months_per_mailing_list_subscriber)
{
  const $months = Math.ceil(months_before_experienced+active_months+(lifespan_in_months_per_piece_of_content*2 + lifespan_in_months_per_mailing_list_subscriber));
  if ($months > 12*50) {
    return 12*50
  }
  return $months;
}
);
  main.variable(observer("locale")).define("locale", function(){return(
{
  "decimal": ".",
  "thousands": " ",
  "grouping": [3],
  "currency": ["", " €"],
  "dateTime": "%a %b %e %X %Y",
  "date": "%d.%m.%Y",
  "time": "%H:%M:%S",
  "periods": ["AM", "PM"],
  "days": ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
  "shortDays": ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
  "months": ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
  "shortMonths": ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]
}
)});
  main.variable(observer()).define(["md"], function(md){return(
md`# Imports`
)});
  main.variable(observer("vegalite")).define("vegalite", ["require"], function(require){return(
require('@observablehq/vega-lite')
)});
  main.variable(observer("embed")).define("embed", ["require"], function(require){return(
require("vega-embed@4")
)});
  const child1 = runtime.module(define1);
  main.import("slider", child1);
  const child2 = runtime.module(define2);
  main.import("logSlider", child2);
  const child3 = runtime.module(define3);
  main.import("ternarySlider", child3);
  const child4 = runtime.module(define4);
  main.import("Button", child4);
  main.import("Checkbox", child4);
  main.import("Toggle", child4);
  main.import("Radio", child4);
  main.import("Range", child4);
  main.import("Select", child4);
  main.import("Text", child4);
  main.import("Textarea", child4);
  main.import("Search", child4);
  main.import("Table", child4);
  main.variable(observer("d3")).define("d3", ["require"], function(require){return(
require("d3-format@2")
)});
  return main;
}
