// https://observablehq.com/@yurivish/ternary-slider@1096
export default function define(runtime, observer) {
  const main = runtime.module();
  main.variable(observer()).define(["md"], function(md){return(
md`# Ternary Slider`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`When building user interfaces it is occasionally useful to have the user specify proportions between things.

If the choice is between _exactly three things_, it can be represented by a [ternary](https://en.wikipedia.org/wiki/Ternary_plot) slider where every point in the triangle corresponds to a unique composition of those three things.`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`## Example`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`For example, a [whiskey sour](https://en.wikipedia.org/wiki/Whiskey_sour) is a drink composed of whiskey, lemon juice, and simple syrup.

Imagine the space of all possible whiskey sours as delinated by the relative proportions of these three ingredients, with pure ingredients at the corners (and an optional cherry or orange slice, sold separately):`
)});
  main.variable(observer("viewof whiskeySour")).define("viewof whiskeySour", ["ternarySlider"], function(ternarySlider){return(
ternarySlider({
  value: [0.5, .25, .25],
  labels: ['Whiskey', 'Sweet', 'Sour']
})
)});
  main.variable(observer("whiskeySour")).define("whiskeySour", ["Generators", "viewof whiskeySour"], (G, _) => G.input(_));
  main.variable(observer()).define(["md","pc","whiskeySour"], function(md,pc,whiskeySour){return(
md`
Your chosen concoction contains **${pc(whiskeySour[0])}** whiskey, **${pc(whiskeySour[1])}** simple syrup, and **${pc(whiskeySour[2])}** lemon juice.
`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`
Move the red dot to choose your composition as point in Whiskey Space. 

Black dots illustrate the ingredient proportions from whiskey sour recipes found online; you can find [the full dataset](#whiskeyTable) below.
`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`## Usage

To use the ternary slider in your own work, import it into your notebook:

~~~js
import {ternarySlider} from '@yurivish/ternary-slider'
~~~

You can now create a slider by calling \`ternarySlider()\`, which will initialize a slider with default settings:
`
)});
  main.variable(observer("viewof result")).define("viewof result", ["ternarySlider"], function(ternarySlider){return(
ternarySlider()
)});
  main.variable(observer("result")).define("result", ["Generators", "viewof result"], (G, _) => G.input(_));
  main.variable(observer()).define(["result"], function(result){return(
result
)});
  main.variable(observer()).define(["md"], function(md){return(
md`
## Customization

The \`slider\` function takes several options: 
* \`value\` specifies an initial composition as a three-element array of numbers, which will be normalized to sum to one.

* \`labels\` specifies labels as a three-element array of strings.

* \`width\` and \`height\` specify the dimensions of the SVG element.
`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`## Acknowledgements`
)});
  main.variable(observer("viewof thanks")).define("viewof thanks", ["ternarySlider"], function(ternarySlider){return(
ternarySlider({
  value: [0.6, 0.22, 0.18],
  labels: ['Andrew', 'Dakota', 'Yuriy']
})
)});
  main.variable(observer("thanks")).define("thanks", ["Generators", "viewof thanks"], (G, _) => G.input(_));
  main.variable(observer()).define(["md","pc","thanks"], function(md,pc,thanks){return(
md`Thanks to Andrew Lin, who did **${pc(thanks[0])}** of the work, Dakota Killpack, who did **${pc(thanks[1])}** of it, and Yuriy Rusko, to whom fell the remaining **${pc(thanks[2])}**. :-)`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`---`
)});
  main.variable(observer("addingYourOwnData")).define("addingYourOwnData", ["md"], function(md){return(
md`
### Appendix: Adding your own data

Additional data can be projected into a slider using a d3 data-join. This is illustrated by the cell below, which plots the recipe circles in the example at the top of the page.

~~~js
import {projectX, projectY} from '@yurivish/ternary-slider'
~~~
`
)});
  main.variable(observer()).define(["viewof whiskeySour","d3","whiskeySours","projectX","projectY"], function($0,d3,whiskeySours,projectX,projectY)
{ // Augment the chart with proportions from whiskey sour recipe data
  let svg = $0
  d3.select(svg)
    // Bind and insert data elements using a data-join
    .selectAll('circle.data').data(whiskeySours).join('circle')
    .attr('class', 'data')
    // `projectX` and `projectY` compute coordinates in [-1, 1] for
    // an input composition.
    .attr('cx', d => svg.R * projectX(svg.corners, d.composition))
    .attr('cy', d => svg.R * projectY(svg.corners, d.composition))
    // Apply visual properties
    .attr('r', 1)
    .attr('stroke', '#fff')
    .attr('fill', '#000')
    // Move the data elements below the drag surface
    .lower()
}
);
  main.variable(observer("whiskeyTable")).define("whiskeyTable", ["md"], function(md){return(
md`### Appendix: Whiskey sour recipe dataset
The recipe data plotted in the top example was collected from the first few pages of Google search results for [whiskey sour recipe](https://www.google.com/search?q=whiskey+sour+recipe).`
)});
  main.variable(observer()).define(["md","whiskeySours"], function(md,whiskeySours)
{
  let keys = ['Title', 'Whiskey', 'Sweet', 'Sour']
  let names = ['Title', 'Whiskey', 'Simple syrup', 'Lemon or Lime']
  return md`
  ${names.join('|')}
  ${keys.map(d => '---').join('|')}
  ${whiskeySours.map(d => keys.map((k, i) => i > 0 ? d[k] : `[${d[k]}](${d.URL})`).join('|')).join('\n')}
  `
}
);
  main.variable(observer()).define(["md"], function(md){return(
md`
### Appendix: Greater than three (additional acknowledgements)

Thanks to Corey Thompson, Ryan Shackleton, and Carl Manaster for their feedback on a draft of this document.
`
)});
  main.variable(observer()).define(["md"], function(md){return(
md`---`
)});
  main.variable(observer("whiskeySours")).define("whiskeySours", ["d3","C"], function(d3,C){return(
d3.csvParse(`Title,URL,Whiskey,Sour,Sweet,Comments
Epicurious,https://www.epicurious.com/recipes/food/views/whiskey-sour-cocktail,2,0.75,0.75,Andrew likes this one
Food Network,https://www.foodnetwork.com/recipes/ina-garten/fresh-whiskey-sours-recipe-2106527,0.75,1,0.67,implied 2:1 simple syrup; recipe says 0.67
International Bartenders Association,https://iba-world.com/iba-official-cocktails/whiskey-sour/,4.5,3,1.5,
Liquor.com,https://www.liquor.com/recipes/whiskey-sour/#gs.74ozsj,2,0.75,0.5,optional 0.5 egg white
Bon Appetit,https://www.bonappetit.com/recipe/whiskey-sour,2,0.75,0.75,"1/2 orange wheel, cherry"
Punch Drink,https://punchdrink.com/recipes/whiskey-sour/,2,0.75,0.75,
The Spruce Eats,https://www.thespruceeats.com/whiskey-sour-recipe-761273,1.5,1.5,0.75,
Jamie Oliver,https://www.jamieoliver.com/drinks-tube/recipe/whiskey-sour/,2,1,0.5,
All Recipes,https://www.allrecipes.com/recipe/138002/classic-whiskey-sour/,5,2,1,
Wine and Glue,https://www.wineandglue.com/whiskey-sour-recipe/,3,2,1.5,2 egg whites
Real Housemoms,https://realhousemoms.com/whiskey-sour/,2,1,0.75,
Tasting Table,https://www.tastingtable.com/cook/recipes/easy-whiskey-sour-cocktail-recipe,2,0.75,0.75,
Powell & Mahoney,https://www.powellandmahoney.com/recipe/whiskey-sour/,2,1,0.5,
The Kitchn,https://www.thekitchn.com/how-to-make-a-whiskey-sour-240335,2,1,0.75,
Food52,https://food52.com/recipes/76034-classic-whiskey-sour,1.25,0.75,0.5,`, d3.autoType).map(d => ({...d, composition: C([d.Whiskey, d.Sweet, d.Sour])}))
)});
  main.variable(observer("projectX")).define("projectX", ["d3"], function(d3){return(
([a, b, c], weights) => 
  d3.sum([a[0] * weights[0], b[0] * weights[1], c[0] * weights[2]])
)});
  main.variable(observer("projectY")).define("projectY", ["d3"], function(d3){return(
([a, b, c], weights) => 
  d3.sum([a[1] * weights[0], b[1] * weights[1], c[1] * weights[2]])
)});
  main.variable(observer("ternarySlider")).define("ternarySlider", ["C","DOM","d3","projectX","projectY","area"], function(C,DOM,d3,projectX,projectY,area){return(
options => {
  // Observable makes a thumbnail from the first canvas or svg or image that is at least 320x200.
  let opts = Object.assign(
    {
      value: [1 / 3, 1 / 3, 1 / 3],
      width: 320,
      height: 320,
      labels: ['A', 'B', 'C']
    },
    options
  );

  // Turn the initial value passed by the user into a composition if it isn't one already
  opts.value = C(opts.value);

  let node = DOM.svg(opts.width, opts.height);
  let svg = d3
    .select(node)
    .attr('viewBox', '-50 -50 100 100')
    .style('overflow', 'visible');

  // The y extent of the corners is [-1, 0.5], or [-50, 25] in view space, due to the rotation of the triangle.
  // Offsetting downwards by 12.5 view points puts the triangle in the center of the svg.
  let yOffset = 12.5;
  let g = svg.append('g').attr('transform', `translate(0, ${yOffset})`);
  let { sin, cos, max, PI } = Math;
  let corners = d3.range(3).map(i => {
    let angle = -PI / 2 + i * ((2 * PI) / 3);
    return [cos(angle), sin(angle)];
  });

  let R = 50;

  let line = d3
    .line()
    .x(([x, y]) => R * x)
    .y(([x, y]) => R * y)
    .curve(d3.curveLinearClosed);

  g.append('path')
    .attr('d', line(corners))
    .attr('fill', 'transparent')
    .attr('stroke-width', 0.5)
    .attr('stroke', '#ccc');

  g.selectAll('text.label')
    .data(corners)
    .join('text')
    .attr('class', 'label')
    .text((d, i) => opts.labels[i])
    .attr('x', ([x, y], i) => R * x)
    .attr('y', ([x, y], i) => R * y + (i == 0 ? -7.5 : 7.5))
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'middle')
    .attr('font-size', '5');

  let handle = g
    .append('circle')
    .attr('cx', R * projectX(corners, opts.value))
    .attr('cy', R * projectY(corners, opts.value))
    .attr('r', 2)
    .attr('fill', 'red');

  // Define a transition to use when fading subcomposition marks
  let t = d3
    .transition()
    .ease(d3.easeCubicOut)
    .duration(300);

  let updateSubcompositions = (composition, doTransition) => {
    g.selectAll('line.projection')
      .data(
        // One datum for each non-degenerate pair of (a, b, c).
        // We show the projection of the value onto each of the three sides of
        // the triangle, and fade the projection out if both endpoints of the
        // sub-composition are zero.
        [
          { composition: C([composition[0], composition[1], 0]), index: 0 },
          { composition: C([0, composition[1], composition[2]]), index: 1 },
          { composition: C([composition[0], 0, composition[2]]), index: 2 }
        ].filter(d => d3.sum(d.composition) > 0), // Invalid entries contain NaN.
        d => d.index
      )
      .join(
        enter =>
          enter
            .append('line')
            .attr('class', 'projection')
            .attr('stroke-width', 0.5)
            .attr('pointer-events', 'none')
            .attr('stroke-linecap', 'round')
            .attr('stroke', 'red')
            .attr('opacity', doTransition ? 0 : 1)
            .call(
              enter => doTransition && enter.transition(t).attr('opacity', 1)
            ),
        update => update,
        exit =>
          exit
            .transition(t)
            .attr('opacity', 0)
            .remove()
      )
      .attr(
        'transform',
        d =>
          `rotate(${60 + d.index * 120}, ${R *
            projectX(corners, d.composition)}, ${R *
            projectY(corners, d.composition)})`
      )
      .attr('x1', d => R * projectX(corners, d.composition))
      .attr('y1', d => R * projectY(corners, d.composition) + 0)
      .attr('x2', d => R * projectX(corners, d.composition))
      .attr('y2', d => R * projectY(corners, d.composition) - 1.5);
  };

  let dragged = () => {
    let p = [d3.event.x / R, d3.event.y / R];
    let [a, b, c] = corners;
    let total = area(a, b, c);
    let composition = C(
      [area(b, c, p), area(c, a, p), area(a, b, p)].map(a => max(0, a) / total)
    );
    updateSubcompositions(composition, true);
    handle
      .attr('cx', R * projectX(corners, composition))
      .attr('cy', R * projectY(corners, composition));
    node.value = composition;
    node.dispatchEvent(new CustomEvent("input", { bubbles: true }));
  };

  updateSubcompositions(opts.value, false);

  // Allow dragging anywhere on the surface of the SVG. For d3.event.x and d3.event.y to determine
  // the desired coordinates, put this rect inside the offset <g> and give it a transform to undo
  // the parent transform.
  g.append('rect')
    .attr('width', 100)
    .attr('height', 100)
    .attr('x', -50)
    .attr('y', -50)
    .style('cursor', 'pointer')
    .attr('transform', `translate(0, ${-yOffset})`)
    .attr('fill', 'transparent')
    .call(
      d3
        .drag()
        .on('drag', dragged)
        .on('start', dragged)
    );

  // Store the triangle "radius" and normalized corner coordinates on the SVG node
  // for easy access for e.g. projecting additional data inside the slider.
  node.R = R;
  node.corners = corners;
  node.labels = opts.labels;
  node.value = opts.value;
  return node;
}
)});
  main.variable(observer("C")).define("C", ["d3"], function(d3){return(
arr => {
  let total = d3.sum(arr) 
  return arr.map(d => d / total)
}
)});
  main.variable(observer("area")).define("area", function(){return(
([xa, ya], [xb, yb], [xc, yc]) => 0.5 * (xa*yb + xb*yc + xc*ya - xa*yc - xc*yb - xb*ya)
)});
  main.variable(observer("d3")).define("d3", ["require"], function(require){return(
require('d3@5')
)});
  main.variable(observer("pc")).define("pc", ["d3"], function(d3){return(
d3.format('.0%')
)});
  return main;
}
