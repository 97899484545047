import novelaTheme from "blog-theme/src/gatsby-plugin-theme-ui"

export default {
  ...novelaTheme,
  // initialColorModeName: `light`,
  colors: {
    ...novelaTheme.colors,
    primary: "#13002d",
    secondary: "#73737D",
    accent: "#5d5888",
    /*
    grey: '#73737D',
    background: '#fff',
    */
  },
}
